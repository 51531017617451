import { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import { context } from "../../context/context";
import { db } from "../../firebase/firebase";
import { Skeleton } from "@mui/material";
import { formatterZero, getTime, toFixedIfNecessary } from "../../utils/utils";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import RealEstateModalAffiliate from "../../modals/RealEstateModalAffiliate";

const RealEstateAffiliateTable = ({ user, prices, accounts }) => {
  const { id } = user;

  const tabs = ["Open Investments", "Closed Investments"];
  const [activeTab, setActiveTab] = useState("Open Investments");
  const { userData } = useContext(context);

  const [isLoading, setIsLoading] = useState(true);
  const [investments, setInvestments] = useState([]);
  // const [userList, setUserList] = useState([]);

  // toast
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(" ");
  const [toastType, setToastType] = useState(undefined);

  // const { id } = userData;

  useEffect(() => {
    function getInvestments() {
      const q = query(collection(db, "investments"), where("user", "==", id));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const investList = [];
        querySnapshot.forEach((doc) => {
          investList.push(doc.data());
        });
        setInvestments(investList);
        setTimeout(() => {
          setIsLoading(false);
        }, 600);
      });
    }

    if (id) {
      getInvestments();
    }
  }, [id]);

  const dummyList = [
    {
      id: "2PA6DDXmkgO2dfr4rh31fgSukHFG3",
      firstname: "Sam",
      lastname: "Wells",
      photoURL: null,
      admin: true,
      registerDate: "2 days ago",
      lastLogin: "1 day ago",
      presence: "online",
      verified: true,
      email: "samwells333@gmail.com",
      currency: "USD",
      blocked: false, // or active
      // status: "active", // or blocked
    },
    {
      id: "GKOqJzyMllht7LIcWuyB2rcCFpFGD3",
      firstname: "Russel",
      lastname: "Carter",
      photoURL: null,
      admin: false,
      registerDate: "2 days ago",
      lastLogin: "1 day ago",
      presence: "online",
      verified: true,
      email: "samwells333@gmail.com",
      currency: "USD",
      blocked: false, // or active
      // status: "active", // or blocked
    },
    {
      id: "g9xpimLpyryX1XGk2KJEy1d6ggTUft2",
      firstname: "Global",
      lastname: "Finex",
      photoURL:
        "https://firebasestorage.googleapis.com/v0/b/finance-test-5f328.appspot.com/o/logo.pngThu%20Feb%2016%202023%2002%3A58%3A39%20GMT%2B0100%20(West%20Africa%20Standard%20Time)?alt=media&token=9f3ff58c-d249-4af2-8c44-b39370f137ab",
      admin: false,
      registerDate: "2 days ago",
      lastLogin: "1 day ago",
      presence: "online",
      verified: true,
      email: "support@globalfinex.net",
      currency: "USD",
      blocked: false, // or active
      // status: "active", // or blocked
    },
    {
      id: "g9xpimLpX1XGk2KJErumfyy1d6ggTUft2",
      firstname: "Global",
      lastname: "Finex",
      photoURL:
        "https://firebasestorage.googleapis.com/v0/b/finance-test-5f328.appspot.com/o/logo.pngThu%20Feb%2016%202023%2002%3A58%3A39%20GMT%2B0100%20(West%20Africa%20Standard%20Time)?alt=media&token=9f3ff58c-d249-4af2-8c44-b39370f137ab",
      admin: false,
      registerDate: "2 days ago",
      lastLogin: "1 day ago",
      presence: "online",
      verified: true,
      email: "support@globalfinex.net",
      currency: "USD",
      blocked: false, // or active
      // status: "active", // or blocked
    },
    {
      id: "g9xpimLpX1XGk2KJryjyrjyrEy1d6ggTUft2",
      firstname: "Global",
      lastname: "Finex",
      photoURL:
        "https://firebasestorage.googleapis.com/v0/b/finance-test-5f328.appspot.com/o/logo.pngThu%20Feb%2016%202023%2002%3A58%3A39%20GMT%2B0100%20(West%20Africa%20Standard%20Time)?alt=media&token=9f3ff58c-d249-4af2-8c44-b39370f137ab",
      admin: false,
      registerDate: "2 days ago",
      lastLogin: "1 day ago",
      presence: "online",
      verified: true,
      email: "support@globalfinex.net",
      currency: "USD",
      blocked: false, // or active
      // status: "active", // or blocked
    },
  ];

  const [selectedReal, setSelectedReal] = useState(null);

  const [real, setReal] = useState(false);

  async function handleInvest(investment) {
    const pool = {
      ref: investment.ref,
      name: investment.projectTitle,
      asset: "USD",
      amount: investment.amount,
      duration: investment.daysLeft,
      roi: investment.projectRoi,
      affiliated: investment.affiliated,
      currentInvested:
        investment.affiliates && investment.affiliates[userData.id]
          ? investment.affiliates[userData.id]?.investedAmount
          : 0,
      currentRoi:
        investment.affiliates && investment.affiliates[userData.id]
          ? investment.affiliates[userData.id]?.investedRoi
          : 0,
    };
    setSelectedReal(pool);
    setReal(true);
  }

  return (
    <Table>
      {real && (
        <RealEstateModalAffiliate
          open={{ real, setReal }}
          pool={selectedReal}
          accounts={accounts}
          prices={prices}
          user={user}
        />
      )}

      {isLoading ? (
        <div
          style={{
            paddingBottom: "24px",
            // position: "relative",
            maxHeight: "100%",
            width: "100%",
            overflow: "scroll",
          }}
          className="scrollbar-hide"
        >
          <div
            style={{
              color: "white",
              fontSize: "16px",
              fontWeight: "600",
              backgroundColor: "#1B1F2D",
              width: "100%",
              padding: "16px 24px",
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
              display: "flex",
              gap: "32px",
              position: "sticky",
              top: "0",
              zIndex: "999",
              left: "0",
              borderBottom: "1px solid #212945",
            }}
          >
            <p>Open Investments</p>
            <p>Closed Investments</p>
            {/* <p style={{ color: "#ACB3CD" }}>Closed Trades</p> */}
          </div>

          {/* trades */}
          <TradesTableDesktop>
            <th>
              <td className="head-cell asset">
                <p>
                  {" "}
                  <Skeleton
                    variant="rounded"
                    sx={{
                      backgroundColor: "rgba(27, 31, 45)",
                      maxWidth: "100%",
                    }}
                  />
                </p>
              </td>
              <td className="head-cell date">
                <p>
                  {" "}
                  <Skeleton
                    variant="rounded"
                    sx={{
                      backgroundColor: "rgba(27, 31, 45)",
                      maxWidth: "100%",
                    }}
                  />
                </p>
              </td>
              <td className="head-cell direction">
                <p>
                  {" "}
                  <Skeleton
                    variant="rounded"
                    sx={{
                      backgroundColor: "rgba(27, 31, 45)",
                      maxWidth: "100%",
                    }}
                  />
                </p>
              </td>
              <td className="head-cell amount">
                <p>
                  {" "}
                  <Skeleton
                    variant="rounded"
                    sx={{
                      backgroundColor: "rgba(27, 31, 45)",
                      maxWidth: "100%",
                    }}
                  />
                </p>
              </td>
              <td className="head-cell entry">
                <p>
                  {" "}
                  <Skeleton
                    variant="rounded"
                    sx={{
                      backgroundColor: "rgba(27, 31, 45)",
                      maxWidth: "100%",
                    }}
                  />
                </p>
              </td>
              <td className="head-cell pl">
                <p>
                  {" "}
                  <Skeleton
                    variant="rounded"
                    sx={{
                      backgroundColor: "rgba(27, 31, 45)",
                      maxWidth: "100%",
                    }}
                  />
                </p>
              </td>
              <td className="head-cell action"></td>
            </th>

            {dummyList.map((li) => (
              <tr key={li.id}>
                <td className="row-cell asset">
                  <span>
                    <Skeleton
                      variant="circular"
                      width={22}
                      height={22}
                      sx={{
                        backgroundColor: "rgba(27, 31, 45)",
                        maxWidth: "100%",
                      }}
                    />
                    <p>
                      {" "}
                      <Skeleton
                        variant="rounded"
                        width={100}
                        sx={{
                          backgroundColor: "rgba(27, 31, 45)",
                          maxWidth: "100%",
                        }}
                      />
                    </p>
                  </span>
                </td>
                <td className="row-cell date">
                  <p>
                    {" "}
                    <Skeleton
                      variant="rounded"
                      // width={100}
                      sx={{
                        backgroundColor: "rgba(27, 31, 45)",
                        maxWidth: "100%",
                      }}
                    />
                  </p>
                </td>
                <td className="row-cell direction">
                  <p>
                    {" "}
                    <Skeleton
                      variant="rounded"
                      // width={100}
                      sx={{
                        backgroundColor: "rgba(27, 31, 45)",
                        maxWidth: "100%",
                      }}
                    />{" "}
                  </p>
                </td>
                <td className="row-cell amount">
                  <p>
                    {" "}
                    <Skeleton
                      variant="rounded"
                      // width={100}
                      sx={{
                        backgroundColor: "rgba(27, 31, 45)",
                        maxWidth: "100%",
                      }}
                    />{" "}
                  </p>
                </td>
                <td className="row-cell entry">
                  <p>
                    <Skeleton
                      variant="rounded"
                      // width={100}
                      sx={{
                        backgroundColor: "rgba(27, 31, 45)",
                        maxWidth: "100%",
                      }}
                    />
                  </p>
                </td>
                <td className="row-cell pl">
                  <p>
                    <Skeleton
                      variant="rounded"
                      // width={100}
                      sx={{
                        backgroundColor: "rgba(27, 31, 45)",
                        maxWidth: "100%",
                      }}
                    />
                  </p>
                </td>
                <td className="row-cell action">
                  {/* <button> */}
                  <p>
                    <Skeleton
                      variant="rounded"
                      width={70}
                      sx={{
                        backgroundColor: "rgba(27, 31, 45)",
                        maxWidth: "100%",
                      }}
                    />
                  </p>
                  {/* </button> */}
                </td>
              </tr>
            ))}
          </TradesTableDesktop>

          {/* mobile */}
          <TradesTableMobile>
            <>
              {dummyList.map((li) => (
                <div className="table-cell active">
                  <div className="top">
                    <span>
                      <Skeleton
                        variant="circular"
                        width={22}
                        height={22}
                        sx={{
                          backgroundColor: "rgba(27, 31, 45)",
                          maxWidth: "100%",
                        }}
                      />
                      <p>
                        {" "}
                        <Skeleton
                          variant="rounded"
                          width={100}
                          sx={{
                            backgroundColor: "rgba(27, 31, 45)",
                            maxWidth: "100%",
                          }}
                        />
                      </p>
                    </span>

                    {/* <button>Close</button> */}
                  </div>
                  <div className="bottom">
                    <span>
                      <p>
                        {" "}
                        <Skeleton
                          variant="rounded"
                          width={100}
                          sx={{
                            backgroundColor: "rgba(27, 31, 45)",
                            maxWidth: "100%",
                          }}
                        />
                      </p>
                      <p>
                        {" "}
                        <Skeleton
                          variant="rounded"
                          width={100}
                          sx={{
                            backgroundColor: "rgba(27, 31, 45)",
                            maxWidth: "100%",
                          }}
                        />
                      </p>
                    </span>
                    <span>
                      <p>
                        {" "}
                        <Skeleton
                          variant="rounded"
                          width={100}
                          sx={{
                            backgroundColor: "rgba(27, 31, 45)",
                            maxWidth: "100%",
                          }}
                        />
                      </p>
                      <p>
                        {" "}
                        <Skeleton
                          variant="rounded"
                          width={100}
                          sx={{
                            backgroundColor: "rgba(27, 31, 45)",
                            maxWidth: "100%",
                          }}
                        />
                      </p>
                    </span>
                    <span>
                      <p>
                        {" "}
                        <Skeleton
                          variant="rounded"
                          width={100}
                          sx={{
                            backgroundColor: "rgba(27, 31, 45)",
                            maxWidth: "100%",
                          }}
                        />
                      </p>
                      <p>
                        {" "}
                        <Skeleton
                          variant="rounded"
                          width={100}
                          sx={{
                            backgroundColor: "rgba(27, 31, 45)",
                            maxWidth: "100%",
                          }}
                        />
                      </p>
                    </span>
                    <span>
                      <p>
                        {" "}
                        <Skeleton
                          variant="rounded"
                          width={100}
                          sx={{
                            backgroundColor: "rgba(27, 31, 45)",
                            maxWidth: "100%",
                          }}
                        />
                      </p>
                      <p>
                        {" "}
                        <Skeleton
                          variant="rounded"
                          width={100}
                          sx={{
                            backgroundColor: "rgba(27, 31, 45)",
                            maxWidth: "100%",
                          }}
                        />
                      </p>
                    </span>
                    <span>
                      <p>
                        {" "}
                        <Skeleton
                          variant="rounded"
                          width={100}
                          sx={{
                            backgroundColor: "rgba(27, 31, 45)",
                            maxWidth: "100%",
                          }}
                        />
                      </p>
                      <p>
                        {" "}
                        <Skeleton
                          variant="rounded"
                          width={100}
                          sx={{
                            backgroundColor: "rgba(27, 31, 45)",
                            maxWidth: "100%",
                          }}
                        />
                      </p>
                    </span>
                  </div>
                </div>
              ))}
            </>
          </TradesTableMobile>
        </div>
      ) : (
        <div
          style={{
            paddingBottom: "24px",
            maxHeight: "100%",
            width: "100%",
            overflow: "scroll",
          }}
          className="scrollbar-hide"
        >
          <div
            style={{
              color: "white",
              fontSize: "16px",
              fontWeight: "600",
              backgroundColor: "#1B1F2D",
              width: "100%",
              padding: "16px 24px",
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
              display: "flex",
              gap: "32px",
              position: "sticky",
              top: "0",
              zIndex: "999",
              left: "0",
              borderBottom: "1px solid #212945",
            }}
          >
            {tabs.map((tab) => (
              <p
                key={tab}
                style={{
                  color: tab !== activeTab && "#ACB3CD",
                  cursor: "pointer",
                }}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </p>
            ))}
          </div>

          {/* trades */}
          <TradesTableDesktop>
            {/* heading:desktop */}
            {investments.length > 0 && (
              <th>
                <td className="head-cell asset">
                  <p>Project Name</p>
                </td>
                <td className="head-cell date">
                  <p>Date</p>
                </td>
                <td className="head-cell direction">
                  <p>Amount</p>
                </td>
                <td className="head-cell amount">
                  <p>{`${user.userRef?.firstname}'s Amount`}</p>
                </td>
                <td className="head-cell entry">
                  <p>Your Amount</p>
                </td>
                <td className="head-cell pl">
                  <p>Status</p>
                </td>
                <td className="head-cell action"></td>
              </th>
            )}

            {/* open:desktop */}
            {activeTab === "Open Investments" &&
              investments.map(
                (investment) =>
                  investment.status === "open" && (
                    <tr key={investment?.ref}>
                      <td className="row-cell asset">
                        <span>
                          <p>{investment?.projectTitle}</p>
                        </span>
                      </td>
                      <td className="row-cell date">
                        <p>
                          {" "}
                          {getTime(
                            new Date() - investment?.date?.toDate()
                          )}{" "}
                        </p>
                      </td>
                      <td className="row-cell direction">
                        <p>
                          {" "}
                          {formatterZero.format(Number(investment?.amount))}
                        </p>
                      </td>
                      <td className="row-cell amount">
                        <p>
                          {investment?.original
                            ? formatterZero.format(Number(investment?.original))
                            : formatterZero.format(Number(investment?.amount))}
                        </p>
                      </td>
                      <td className="row-cell entry">
                        <p>
                          {investment.affiliates &&
                          investment.affiliates[userData.id]
                            ? formatterZero.format(
                                Number(
                                  investment.affiliates[userData.id]
                                    ?.investedAmount
                                )
                              )
                            : "-"}
                        </p>
                      </td>
                      <td className="row-cell pl">
                        <div className="status_box active">
                          <p>Running</p>
                        </div>
                      </td>
                      <td className="row-cell action">
                        <button onClick={() => handleInvest(investment)}>
                          <p>Invest</p>
                        </button>
                      </td>
                    </tr>
                  )
              )}

            {/* closed:: desktop */}
            {activeTab === "Closed Investments" &&
              investments.map(
                (investment) =>
                  investment.status === "closed" && (
                    <tr key={investment?.ref}>
                      <td className="row-cell asset">
                        <span>
                          <p>{investment?.projectTitle}</p>
                        </span>
                      </td>
                      <td className="row-cell date">
                        <p>
                          {" "}
                          {getTime(
                            new Date() - investment?.date?.toDate()
                          )}{" "}
                        </p>
                      </td>
                      <td className="row-cell direction">
                        <p>
                          {" "}
                          {formatterZero.format(Number(investment?.amount))}
                        </p>
                      </td>
                      <td className="row-cell amount">
                        <p>
                          {" "}
                          {investment?.original
                            ? formatterZero.format(Number(investment?.original))
                            : formatterZero.format(Number(investment?.amount))}
                        </p>
                      </td>
                      <td className="row-cell entry">
                        <p>
                          {investment.affiliates &&
                          investment.affiliates[userData.id]
                            ? formatterZero.format(
                                Number(
                                  investment.affiliates[userData.id]
                                    ?.investedAmount
                                )
                              )
                            : "-"}
                        </p>
                      </td>
                      <td className="row-cell pl">
                        <div className="status_box inactive">
                          <p>Ended</p>
                        </div>
                      </td>
                      <td className="row-cell action">
                        {/* <button onClick={() => handleInvest(staking)}>
                          <p>Invest</p>
                        </button> */}
                      </td>
                    </tr>
                  )
              )}
          </TradesTableDesktop>

          {/* mobile */}
          <TradesTableMobile>
            {/* open : mobile*/}
            {activeTab === "Open Investments" &&
              investments.map(
                (investment) =>
                  investment.status === "open" && (
                    // <div>
                    <div className="table-cell active" key={investment.ref}>
                      <div className="top">
                        <button onClick={() => handleInvest(investment)}>
                          <p>Invest</p>
                        </button>
                      </div>
                      <div className="bottom">
                        <span>
                          <p className="title">Project</p>
                          <p className="detail">{investment.projectTitle}</p>
                        </span>
                        <span>
                          <p className="title">Date</p>
                          <p className="detail">
                            {" "}
                            {getTime(
                              new Date() - investment?.date?.toDate()
                            )}{" "}
                          </p>
                        </span>
                        <span>
                          <p className="title">Amount</p>
                          <p className="detail">
                            {" "}
                            {formatterZero.format(Number(investment?.amount))}
                          </p>
                        </span>
                        <span>
                          <p className="title">{`${user.userRef?.firstname}'s Amount`}</p>
                          <p className="detail">
                            {investment?.original
                              ? formatterZero.format(
                                  Number(investment?.original)
                                )
                              : formatterZero.format(
                                  Number(investment?.amount)
                                )}
                          </p>
                        </span>
                        <span>
                          <p className="title">Your Amount</p>
                          <p className="detail">
                            {investment.affiliates &&
                            investment.affiliates[userData.id]
                              ? formatterZero.format(
                                  Number(
                                    investment.affiliates[userData.id]
                                      ?.investedAmount
                                  )
                                )
                              : "-"}
                          </p>
                        </span>
                      </div>
                    </div>
                  )
                // </div>
              )}

            {/* closed : mobile */}
            {activeTab === "Closed Investments" &&
              investments.map(
                (investment) =>
                  investment.status === "open" && (
                    // <div>
                    <div className="table-cell active" key={investment.ref}>
                      <div className="top">
                        {/* <button onClick={() => handleInvest(investment)}>
                          <p>Invest</p>
                        </button> */}
                      </div>
                      <div className="bottom">
                        <span>
                          <p className="title">Project</p>
                          <p className="detail">{investment.projectTitle}</p>
                        </span>
                        <span>
                          <p className="title">Date</p>
                          <p className="detail">
                            {" "}
                            {getTime(
                              new Date() - investment?.date?.toDate()
                            )}{" "}
                          </p>
                        </span>
                        <span>
                          <p className="title">Amount</p>
                          <p className="detail">
                            {" "}
                            {formatterZero.format(Number(investment?.amount))}
                          </p>
                        </span>
                        <span>
                          <p className="title">{`${user.userRef?.firstname}'s Amount`}</p>
                          <p className="detail">
                            {investment?.original
                              ? formatterZero.format(
                                  Number(investment?.original)
                                )
                              : formatterZero.format(
                                  Number(investment?.amount)
                                )}
                          </p>
                        </span>
                        <span>
                          <p className="title">Your Amount</p>
                          <p className="detail">
                            {investment.affiliates &&
                            investment.affiliates[userData.id]
                              ? formatterZero.format(
                                  Number(
                                    investment.affiliates[userData.id]
                                      ?.investedAmount
                                  )
                                )
                              : "-"}
                          </p>
                        </span>
                      </div>
                    </div>
                  )
                // </div>
              )}
          </TradesTableMobile>
        </div>
      )}
    </Table>
  );
};

const Table = styled.div`
  background-color: #151823;
  position: relative;
  border-radius: 12px;
  height: 100%;

  .status_box {
    padding: 4px 8px;
    border: 1px solid #c6f6d8;
    background-color: #f0fdf4;
    border-radius: 6px;
    max-width: max-content;
    display: flex;
    gap: 4px;
    align-items: center;
  }

  .status_box p {
    font-size: 14px;
    font-weight: 500;
    color: #15803d;
    line-height: 20px;
  }

  .action_button {
    padding: 4px 24px;
    border: 1px solid #212945;
    background-color: #1b1f2d;
    border-radius: 6px;
    max-width: max-content;
    display: flex;
    gap: 4px;
    align-items: center;
    color: #c6f6d82b;
    cursor: pointer;
    place-self: flex-start;
  }

  .action_button p {
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
  }

  .status_box.active {
    border: 1px solid #c6f6d8;
    background-color: #f0fdf4;
  }

  .status_box.active p {
    color: #15803d;
  }

  .status_box.inactive {
    border: 1px solid #4b3547;
    background-color: #2d202b;
  }

  .status_box.inactive p {
    color: #d55763;
  }

  .status_box.pending {
    border: 1px solid #b2ddff;
    background-color: #eff8ff;
  }

  .status_box.pending p {
    color: #175cd3;
  }
`;

const TradesTableDesktop = styled.table`
  /* display: grid; */
  width: 100%;
  display: none;
  /* max-width: 100%;
  overflow-x: scroll;
  overflow-y: scroll; */

  @media screen and (min-width: 1600px) {
    display: block;
  }

  th {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #212945;
  }

  tr {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .head-cell {
    padding: 0px 24px;
    height: 44px;
    white-space: nowrap;
    text-align: left;
    display: grid;
    align-content: center;
    color: #bac2de;
    font-size: 14px;
    font-weight: 600;
  }

  .row-cell {
    padding: 0px 24px;
    height: 72px;
    white-space: nowrap;
    text-align: left;
    display: grid;
    align-content: center;
    color: white;
  }

  .asset {
    max-width: 320px;
    width: 100%;
  }

  .asset span {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  .asset span img {
    width: 20px;
    height: 20px;
  }

  .asset span p {
    font-size: 14px;
    font-weight: 600;
    color: white;
  }

  .date {
    max-width: 120px;
    width: 100%;
  }

  .direction {
    max-width: 120px;
    width: 100%;
  }

  .amount {
    max-width: 200px;
    width: 100%;
  }

  .entry {
    max-width: 200px;
    width: 100%;
  }

  .pl {
    max-width: 120px;
    width: 100%;
  }

  .action {
    max-width: 120px;
    width: 100%;
    /* background-color: yellow; */
    display: grid;
    justify-content: start;
  }

  .action button {
    padding: 8px 16px;
    color: white;
    background-color: transparent;
    border: 1px solid #212945;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }

  .action button:hover {
    background-color: rgba(27, 31, 45);
    border: 1px solid rgba(27, 31, 45);
  }
`;

const TradesTableMobile = styled.div`
  display: none;

  @media screen and (max-width: 1600px) {
    display: block;
  }

  .table-cell {
    padding: 24px;
    cursor: pointer;
  }

  .table-cell.active .bottom {
    display: block;
  }

  .table-cell:hover {
    background-color: #1b1f2d;
  }

  .top {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .top span {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  .top img {
    width: 20px;
    height: 20px;
  }

  .top p {
    font-size: 16px;
    font-weight: 600;
    color: white;
  }

  .top button {
    padding: 8px 16px;
    color: white;
    background-color: transparent;
    border: 1px solid #212945;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }

  .top button:hover {
    background-color: rgba(27, 31, 45);
    border: 1px solid rgba(27, 31, 45);
  }

  .bottom {
    padding-top: 8px;
    box-sizing: border-box;
    display: none;
  }

  .bottom span {
    margin-top: 12px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }

  .title {
    color: #bac2de;
  }

  .detail {
    color: white;
    font-weight: 500;
  }
`;

export default RealEstateAffiliateTable;
