import { useContext, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { styled } from "styled-components";
import { FullButton, MiniAmountBox, TextBox } from "../styled/input/Input";
import Toast from "../hooks/Toast";
import CircularLoader from "../styled/loaders/CircularLoader";
import {
  doc,
  increment,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase/firebase";
import { context } from "../context/context";
import { formatterZero } from "../utils/utils";
import { siteSettings } from "../static";
import emailjs from "@emailjs/browser";

const WithdrawAffiliateBalanceModal = ({ open }) => {
  const { withdraw, setWithdraw } = open;

  const [tooltipMessage, setToolTipMessage] = useState("");

  const balance = 3000;

  const { userData, affiliateBalance } = useContext(context);

  const { id } = userData;

  // const [showToolTip, setShowToolTip] = useState(false);

  // toast
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(" ");
  const [toastType, setToastType] = useState(undefined);

  const amountRef = useRef();
  const [amount, setAmount] = useState(undefined);
  const [amountError, setAmountError] = useState(false);
  function handleAmount(e) {
    const { value } = e.target;

    if (value) {
      setAmountError(false);
      setAmount(value);
    } else {
      setAmount("");
    }
  }

  const nameRef = useRef();
  const [name, setName] = useState(undefined);
  //   function handleName(e) {
  //     const { value } = e.target;

  //     if (value) {
  //       // console.log(value);
  //       setName(value);
  //     } else {
  //       setName("");
  //     }
  //   }

  //   function reset() {
  //     if (amountRef) {
  //       amountRef.current.value = "";
  //     }

  //     if (nameRef) {
  //       nameRef.current.value = "";
  //     }

  //     setAmount(undefined);
  //     setName(undefined);
  //   }

  const [isWithdrawing, setIsWithdrawing] = useState(false);

  async function handleWithdrawal() {
    if (Number(amount) > Number(affiliateBalance)) {
      setAmountError(true);
      setToastType("error");
      setToastMessage("Insufficient balance");
      setOpenToast(true);
      return;
    }

    setIsWithdrawing(true);

    const q = doc(db, "balances", id);
    const key = `affiliateBalance`;

    try {
      await updateDoc(q, {
        [key]: increment(-Number(amount)),
      }).then(() => {
        withdrawBalance();
      });
    } catch (error) {
      setIsWithdrawing(false);
      setToastType("error");
      setToastMessage("Withdrawal failed. Please try again later.");
      setOpenToast(true);
    }
  }

  async function withdrawBalance() {
    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      id.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    await setDoc(doc(db, "affiliateWithdrawals", str), {
      ref: str,
      user: id,
      type: "Affiliate",
      amount: Number(amount),
      asset: "USD",
      date: serverTimestamp(),
      totalInUSD: Number(amount),
      status: "pending",
      userRef: {
        name: userData.firstname + " " + userData.lastname,
        photo: userData.photoURL ? userData.photoURL : null,
        admin: userData.admin,
        email: userData.email,
      },
    })
      .then(() => {
        sendAdminNotification(str);
        sendAdminEmail();
      })
      .catch((error) => {
        setIsWithdrawing(false);
        setToastType("error");
        setToastMessage("Withdrawal failed. Please try again later.");
        setOpenToast(true);
      });
  }

  // admin notified
  async function sendAdminNotification(ref) {
    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      id.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    await setDoc(doc(db, "adminNotifications", str), {
      ref: str,
      type: "Withdrawal",
      message: "Made an affiliate withdrawal of " + amount + " USD",
      user: id,
      read: false,
      date: serverTimestamp(),
      userRef: {
        name: userData.firstname + " " + userData.lastname,
        photo: userData.photoURL ? userData.photoURL : null,
        email: userData.email,
      },
      withdrawalRef: {
        ref,
      },
    })
      .then(() => {
        setIsWithdrawing(false);
        setToastType("success");
        setToastMessage("Withdrawal request successful");
        setOpenToast(true);
        setTimeout(() => {
          setWithdraw(false);
        }, 700);
      })
      .catch((error) => {
        setIsWithdrawing(false);
        setToastType("error");
        setToastMessage("Withdrawal failed. Please try again later.");
        setOpenToast(true);
      });
  }

  async function sendAdminEmail() {
    const params = {
      to_name: "Admin",
      details: `A new affiliate withdrawal request was submitted on your platform.`,
      action_name: "Withdrawal",
      logo_link: `${siteSettings.ogLink}/logo.png`,
      to_login: `${siteSettings.link}`,
      from_name: `${siteSettings.name}`,
      to_email: `${siteSettings.supportEmail}`,
    };

    emailjs
      .send("service_m18ywcp", "template_fwhr0oo", params, "9IOr2_lHheTH7RW1k")
      .then(() => {})
      .catch((error) => {});
  }

  return (
    <>
      {openToast && (
        <Toast
          open={{ openToast, setOpenToast }}
          message={toastMessage}
          type={toastType}
        />
      )}

      <Modal
        open={withdraw}
        onClose={() => setWithdraw(false)}
        style={{
          display: "flex",
          placeContent: "center",
          zIndex: "10001",
          padding: "12px",
        }}
      >
        <ModalStandard className="scrollbar-hide">
          <div className="modal_top">
            <p>Withdraw Affiliate balance</p>

            <svg
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setWithdraw(!withdraw)}
              style={{ cursor: "pointer" }}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.8647 0.366365C12.3532 -0.122122 13.1451 -0.122122 13.6336 0.366365C14.1221 0.854853 14.1221 1.64685 13.6336 2.13533L8.88929 6.87968L13.8743 11.8647C14.3628 12.3532 14.3628 13.1451 13.8743 13.6336C13.3858 14.1221 12.5938 14.1221 12.1053 13.6336L7.12032 8.64864L2.13533 13.6336C1.64685 14.1221 0.854853 14.1221 0.366366 13.6336C-0.122122 13.1451 -0.122122 12.3532 0.366366 11.8647L5.35136 6.87968L0.607014 2.13533C0.118527 1.64685 0.118527 0.854853 0.607014 0.366365C1.0955 -0.122122 1.8875 -0.122122 2.37598 0.366365L7.12032 5.11071L11.8647 0.366365Z"
                fill="#858DAD"
              />
            </svg>
          </div>

          <div className="modal_content">
            <div className="top">
              <div
                style={{
                  fontSize: "14px",
                  padding: "10px 16px",
                  border: "1px solid #222739",
                  borderRadius: "12px",
                  color: "#d4dcf7",
                  lineHeight: "24px",
                  fontWeight: "500",
                }}
              >
                <p>Your withdrawal will be sent to your main USD balance.</p>
              </div>

              <MiniAmountBox
                className="amount_box variant"
                // className={
                //   amountError ? "amount_box error variant" : "amount_box variant"
                // }
              >
                <div className="label">
                  <p>Amount:</p>
                  <img
                    src="./assets/misc/info.svg"
                    alt=""
                    className="error_inform"
                    id="popcorn"
                    // onClick={() => setShowToolTip(!showToolTip)}
                  />
                  {/* {showToolTip && (
                  <div className="tooltip" id="tooltip">
                    {tooltipMessage}
                  </div>
                )} */}
                </div>

                <div className="wrapper">
                  <input
                    type="number"
                    placeholder="1000"
                    onChange={handleAmount}
                    ref={amountRef}
                  />

                  <span className="asset">
                    <p>USD</p>
                    {/* <p>{selectedAsset.asset}</p> */}
                  </span>
                </div>

                {balance && (
                  <div className="captions" style={{ marginTop: "8px" }}>
                    <span>
                      <p className="caption">Current balance</p>
                      <p className="value">
                        {formatterZero.format(affiliateBalance)}
                      </p>
                    </span>
                  </div>
                )}
              </MiniAmountBox>
            </div>

            <div className="bottom">
              <FullButton
                onClick={handleWithdrawal}
                disabled={isWithdrawing || amountError || !amount}
                className={
                  (isWithdrawing || amountError || !amount) && "disabled"
                }
              >
                {isWithdrawing ? (
                  <div style={{ padding: "8px" }}>
                    <CircularLoader bg="#cccccc" size="28" color="#ffffff" />
                  </div>
                ) : (
                  <p>Withdraw</p>
                )}
              </FullButton>
            </div>
          </div>
        </ModalStandard>
      </Modal>
    </>
  );
};

const ModalStandard = styled.div`
  background-color: #151823;
  border-radius: 12px;
  max-width: 430px;
  place-self: center;
  width: 100%;
  border: 1px solid transparent;
  z-index: 10001;

  .captions {
    display: grid;
    gap: 12px;
    /* margin-top: 12px; */
  }

  .captions span {
    display: flex;
    justify-content: space-between;
  }

  .captions .caption {
    font-size: 14px;
    color: #bac2de;
    font-weight: 600;
  }

  .captions .value {
    font-size: 14px;
    color: white;
    font-weight: 600;
  }

  .bottom {
    margin-top: 32px;
  }

  .bottom button {
    cursor: pointer;
    width: 100%;
    background-color: #0c6cf2;
    padding: 12px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
    margin-top: 32px;
    font-family: "Inter";
  }

  .bottom button:hover {
    background-color: #0a57c2;
  }

  .modal_top {
    color: white;
    font-size: 16px;
    font-weight: 600;
    background-color: #1b1f2d;
    width: 100%;
    padding: 14px 30px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    position: sticky;
    top: 0;
    z-index: 999;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal_content {
    padding: 24px;
  }

  .modal_content .top {
    display: grid;
    gap: 24px;
  }

  .slide {
    color: white;
    /* margin-left: 30px; */
  }

  .slider {
    max-width: 100%;
    overflow-x: scroll;
    display: flex;
    gap: 12px;
    overflow-y: hidden;
  }

  .slide img {
    /* margin-left: 12px; */
    aspect-ratio: 4.135/ 5.845;
    width: 100%;
  }
`;

export default WithdrawAffiliateBalanceModal;
