import { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import { context } from "../../context/context";
import { db } from "../../firebase/firebase";
import { Skeleton } from "@mui/material";
import { formatterZero, getTime, toFixedIfNecessary } from "../../utils/utils";
import CloseTradeModal from "../../modals/CloseTradeModal";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import StakeModalAffiliate from "../../modals/StakeModalAffiliate";

const StakingsAffiliateTable = ({ user, prices, accounts }) => {
  const { id } = user;

  const tabs = ["Open Stakings", "Closed Stakings"];
  const [activeTab, setActiveTab] = useState("Open Stakings");
  const { userData } = useContext(context);

  const [isLoading, setIsLoading] = useState(true);
  const [stakings, setStakings] = useState([]);
  // const [userList, setUserList] = useState([]);

  // toast
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(" ");
  const [toastType, setToastType] = useState(undefined);

  // const { id } = userData;

  useEffect(() => {
    function getStakings() {
      const q = query(collection(db, "stakings"), where("user", "==", id));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const stakingList = [];
        querySnapshot.forEach((doc) => {
          stakingList.push(doc.data());
        });
        setStakings(stakingList);
        setTimeout(() => {
          setIsLoading(false);
        }, 600);
      });
    }

    if (id) {
      getStakings();
    }
  }, [id]);

  const dummyList = [
    {
      id: "2PA6DDXmkgO2dfr4rh31fgSukHFG3",
      firstname: "Sam",
      lastname: "Wells",
      photoURL: null,
      admin: true,
      registerDate: "2 days ago",
      lastLogin: "1 day ago",
      presence: "online",
      verified: true,
      email: "samwells333@gmail.com",
      currency: "USD",
      blocked: false, // or active
      // status: "active", // or blocked
    },
    {
      id: "GKOqJzyMllht7LIcWuyB2rcCFpFGD3",
      firstname: "Russel",
      lastname: "Carter",
      photoURL: null,
      admin: false,
      registerDate: "2 days ago",
      lastLogin: "1 day ago",
      presence: "online",
      verified: true,
      email: "samwells333@gmail.com",
      currency: "USD",
      blocked: false, // or active
      // status: "active", // or blocked
    },
    {
      id: "g9xpimLpyryX1XGk2KJEy1d6ggTUft2",
      firstname: "Global",
      lastname: "Finex",
      photoURL:
        "https://firebasestorage.googleapis.com/v0/b/finance-test-5f328.appspot.com/o/logo.pngThu%20Feb%2016%202023%2002%3A58%3A39%20GMT%2B0100%20(West%20Africa%20Standard%20Time)?alt=media&token=9f3ff58c-d249-4af2-8c44-b39370f137ab",
      admin: false,
      registerDate: "2 days ago",
      lastLogin: "1 day ago",
      presence: "online",
      verified: true,
      email: "support@globalfinex.net",
      currency: "USD",
      blocked: false, // or active
      // status: "active", // or blocked
    },
    {
      id: "g9xpimLpX1XGk2KJErumfyy1d6ggTUft2",
      firstname: "Global",
      lastname: "Finex",
      photoURL:
        "https://firebasestorage.googleapis.com/v0/b/finance-test-5f328.appspot.com/o/logo.pngThu%20Feb%2016%202023%2002%3A58%3A39%20GMT%2B0100%20(West%20Africa%20Standard%20Time)?alt=media&token=9f3ff58c-d249-4af2-8c44-b39370f137ab",
      admin: false,
      registerDate: "2 days ago",
      lastLogin: "1 day ago",
      presence: "online",
      verified: true,
      email: "support@globalfinex.net",
      currency: "USD",
      blocked: false, // or active
      // status: "active", // or blocked
    },
    {
      id: "g9xpimLpX1XGk2KJryjyrjyrEy1d6ggTUft2",
      firstname: "Global",
      lastname: "Finex",
      photoURL:
        "https://firebasestorage.googleapis.com/v0/b/finance-test-5f328.appspot.com/o/logo.pngThu%20Feb%2016%202023%2002%3A58%3A39%20GMT%2B0100%20(West%20Africa%20Standard%20Time)?alt=media&token=9f3ff58c-d249-4af2-8c44-b39370f137ab",
      admin: false,
      registerDate: "2 days ago",
      lastLogin: "1 day ago",
      presence: "online",
      verified: true,
      email: "support@globalfinex.net",
      currency: "USD",
      blocked: false, // or active
      // status: "active", // or blocked
    },
  ];

  // const [selectedCloseTrade, setSelectedCloseTrade] = useState(undefined);
  // const [closeTrade, setCloseTrade] = useState(false);

  // function handleCloseTrade(trade) {
  //   setSelectedCloseTrade(trade);
  //   setCloseTrade(true);
  // }

  // staking: {
  //   affiliated: true,
  //   affiliates: {
  //     'ienirir': {
  //       id: "ienirir",
  //       amount: "73,000",
  //       roi: "43"
  //     }
  //   }
  // }

  const [selectedStake, setSelectedStake] = useState(null);

  const [stake, setStake] = useState(false);

  async function handleInvest(stake) {
    const pool = {
      ref: stake.ref,
      asset: stake.asset,
      amount: stake.amount,
      duration: stake.daysLeft,
      roi: stake.roi,
      affiliated: stake.affiliated,
      currentInvested:
        stake.affiliates && stake.affiliates[userData.id]
          ? stake.affiliates[userData.id]?.investedAmount
          : 0,
      currentRoi:
        stake.affiliates && stake.affiliates[userData.id]
          ? stake.affiliates[userData.id]?.investedRoi
          : 0,
    };
    setSelectedStake(pool);
    setStake(true);
  }

  return (
    <Table>
      {stake && (
        <StakeModalAffiliate
          open={{ stake, setStake }}
          pool={selectedStake}
          accounts={accounts}
          prices={prices}
          user={user}
        />
      )}
      {/* {closeTrade && (
        <CloseTradeModal
          open={{ closeTrade, setCloseTrade }}
          details={selectedCloseTrade}
        />
      )} */}

      {isLoading ? (
        <div
          style={{
            paddingBottom: "24px",
            // position: "relative",
            maxHeight: "100%",
            width: "100%",
            overflow: "scroll",
          }}
          className="scrollbar-hide"
        >
          <div
            style={{
              color: "white",
              fontSize: "16px",
              fontWeight: "600",
              backgroundColor: "#1B1F2D",
              width: "100%",
              padding: "16px 24px",
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
              display: "flex",
              gap: "32px",
              position: "sticky",
              top: "0",
              zIndex: "999",
              left: "0",
              borderBottom: "1px solid #212945",
            }}
          >
            <p>Open Stakings</p>
            <p>Closed Stakings</p>
            {/* <p style={{ color: "#ACB3CD" }}>Closed Trades</p> */}
          </div>

          {/* trades */}
          <TradesTableDesktop>
            <th>
              <td className="head-cell asset">
                <p>
                  {" "}
                  <Skeleton
                    variant="rounded"
                    sx={{
                      backgroundColor: "rgba(27, 31, 45)",
                      maxWidth: "100%",
                    }}
                  />
                </p>
              </td>
              <td className="head-cell date">
                <p>
                  {" "}
                  <Skeleton
                    variant="rounded"
                    sx={{
                      backgroundColor: "rgba(27, 31, 45)",
                      maxWidth: "100%",
                    }}
                  />
                </p>
              </td>
              <td className="head-cell direction">
                <p>
                  {" "}
                  <Skeleton
                    variant="rounded"
                    sx={{
                      backgroundColor: "rgba(27, 31, 45)",
                      maxWidth: "100%",
                    }}
                  />
                </p>
              </td>
              <td className="head-cell amount">
                <p>
                  {" "}
                  <Skeleton
                    variant="rounded"
                    sx={{
                      backgroundColor: "rgba(27, 31, 45)",
                      maxWidth: "100%",
                    }}
                  />
                </p>
              </td>
              <td className="head-cell entry">
                <p>
                  {" "}
                  <Skeleton
                    variant="rounded"
                    sx={{
                      backgroundColor: "rgba(27, 31, 45)",
                      maxWidth: "100%",
                    }}
                  />
                </p>
              </td>
              <td className="head-cell pl">
                <p>
                  {" "}
                  <Skeleton
                    variant="rounded"
                    sx={{
                      backgroundColor: "rgba(27, 31, 45)",
                      maxWidth: "100%",
                    }}
                  />
                </p>
              </td>
              <td className="head-cell action"></td>
            </th>

            {dummyList.map((li) => (
              <tr key={li.id}>
                <td className="row-cell asset">
                  <span>
                    <Skeleton
                      variant="circular"
                      width={22}
                      height={22}
                      sx={{
                        backgroundColor: "rgba(27, 31, 45)",
                        maxWidth: "100%",
                      }}
                    />
                    <p>
                      {" "}
                      <Skeleton
                        variant="rounded"
                        width={100}
                        sx={{
                          backgroundColor: "rgba(27, 31, 45)",
                          maxWidth: "100%",
                        }}
                      />
                    </p>
                  </span>
                </td>
                <td className="row-cell date">
                  <p>
                    {" "}
                    <Skeleton
                      variant="rounded"
                      // width={100}
                      sx={{
                        backgroundColor: "rgba(27, 31, 45)",
                        maxWidth: "100%",
                      }}
                    />
                  </p>
                </td>
                <td className="row-cell direction">
                  <p>
                    {" "}
                    <Skeleton
                      variant="rounded"
                      // width={100}
                      sx={{
                        backgroundColor: "rgba(27, 31, 45)",
                        maxWidth: "100%",
                      }}
                    />{" "}
                  </p>
                </td>
                <td className="row-cell amount">
                  <p>
                    {" "}
                    <Skeleton
                      variant="rounded"
                      // width={100}
                      sx={{
                        backgroundColor: "rgba(27, 31, 45)",
                        maxWidth: "100%",
                      }}
                    />{" "}
                  </p>
                </td>
                <td className="row-cell entry">
                  <p>
                    <Skeleton
                      variant="rounded"
                      // width={100}
                      sx={{
                        backgroundColor: "rgba(27, 31, 45)",
                        maxWidth: "100%",
                      }}
                    />
                  </p>
                </td>
                <td className="row-cell pl">
                  <p>
                    <Skeleton
                      variant="rounded"
                      // width={100}
                      sx={{
                        backgroundColor: "rgba(27, 31, 45)",
                        maxWidth: "100%",
                      }}
                    />
                  </p>
                </td>
                <td className="row-cell action">
                  {/* <button> */}
                  <p>
                    <Skeleton
                      variant="rounded"
                      width={70}
                      sx={{
                        backgroundColor: "rgba(27, 31, 45)",
                        maxWidth: "100%",
                      }}
                    />
                  </p>
                  {/* </button> */}
                </td>
              </tr>
            ))}
          </TradesTableDesktop>

          {/* mobile */}
          <TradesTableMobile>
            <>
              {dummyList.map((li) => (
                <div className="table-cell active">
                  <div className="top">
                    <span>
                      <Skeleton
                        variant="circular"
                        width={22}
                        height={22}
                        sx={{
                          backgroundColor: "rgba(27, 31, 45)",
                          maxWidth: "100%",
                        }}
                      />
                      <p>
                        {" "}
                        <Skeleton
                          variant="rounded"
                          width={100}
                          sx={{
                            backgroundColor: "rgba(27, 31, 45)",
                            maxWidth: "100%",
                          }}
                        />
                      </p>
                    </span>

                    <button>Close</button>
                  </div>
                  <div className="bottom">
                    <span>
                      <p>
                        {" "}
                        <Skeleton
                          variant="rounded"
                          width={100}
                          sx={{
                            backgroundColor: "rgba(27, 31, 45)",
                            maxWidth: "100%",
                          }}
                        />
                      </p>
                      <p>
                        {" "}
                        <Skeleton
                          variant="rounded"
                          width={100}
                          sx={{
                            backgroundColor: "rgba(27, 31, 45)",
                            maxWidth: "100%",
                          }}
                        />
                      </p>
                    </span>
                    <span>
                      <p>
                        {" "}
                        <Skeleton
                          variant="rounded"
                          width={100}
                          sx={{
                            backgroundColor: "rgba(27, 31, 45)",
                            maxWidth: "100%",
                          }}
                        />
                      </p>
                      <p>
                        {" "}
                        <Skeleton
                          variant="rounded"
                          width={100}
                          sx={{
                            backgroundColor: "rgba(27, 31, 45)",
                            maxWidth: "100%",
                          }}
                        />
                      </p>
                    </span>
                    <span>
                      <p>
                        {" "}
                        <Skeleton
                          variant="rounded"
                          width={100}
                          sx={{
                            backgroundColor: "rgba(27, 31, 45)",
                            maxWidth: "100%",
                          }}
                        />
                      </p>
                      <p>
                        {" "}
                        <Skeleton
                          variant="rounded"
                          width={100}
                          sx={{
                            backgroundColor: "rgba(27, 31, 45)",
                            maxWidth: "100%",
                          }}
                        />
                      </p>
                    </span>
                    <span>
                      <p>
                        {" "}
                        <Skeleton
                          variant="rounded"
                          width={100}
                          sx={{
                            backgroundColor: "rgba(27, 31, 45)",
                            maxWidth: "100%",
                          }}
                        />
                      </p>
                      <p>
                        {" "}
                        <Skeleton
                          variant="rounded"
                          width={100}
                          sx={{
                            backgroundColor: "rgba(27, 31, 45)",
                            maxWidth: "100%",
                          }}
                        />
                      </p>
                    </span>
                    <span>
                      <p>
                        {" "}
                        <Skeleton
                          variant="rounded"
                          width={100}
                          sx={{
                            backgroundColor: "rgba(27, 31, 45)",
                            maxWidth: "100%",
                          }}
                        />
                      </p>
                      <p>
                        {" "}
                        <Skeleton
                          variant="rounded"
                          width={100}
                          sx={{
                            backgroundColor: "rgba(27, 31, 45)",
                            maxWidth: "100%",
                          }}
                        />
                      </p>
                    </span>
                  </div>
                </div>
              ))}
            </>
          </TradesTableMobile>
        </div>
      ) : (
        <div
          style={{
            paddingBottom: "24px",
            maxHeight: "100%",
            width: "100%",
            overflow: "scroll",
          }}
          className="scrollbar-hide"
        >
          <div
            style={{
              color: "white",
              fontSize: "16px",
              fontWeight: "600",
              backgroundColor: "#1B1F2D",
              width: "100%",
              padding: "16px 24px",
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
              display: "flex",
              gap: "32px",
              position: "sticky",
              top: "0",
              zIndex: "999",
              left: "0",
              borderBottom: "1px solid #212945",
            }}
          >
            {tabs.map((tab) => (
              <p
                key={tab}
                style={{
                  color: tab !== activeTab && "#ACB3CD",
                  cursor: "pointer",
                }}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </p>
            ))}
          </div>

          {/* trades */}
          <TradesTableDesktop>
            {/* heading:desktop */}
            {stakings.length > 0 && (
              <th>
                <td className="head-cell asset">
                  <p>Asset</p>
                </td>
                <td className="head-cell date">
                  <p>Date</p>
                </td>
                <td className="head-cell direction">
                  <p>Amount</p>
                </td>
                <td className="head-cell amount">
                  <p>{`${user.userRef?.firstname}'s Amount`}</p>
                </td>
                <td className="head-cell entry">
                  <p>Your Amount</p>
                </td>
                <td className="head-cell pl">
                  <p>Status</p>
                </td>
                <td className="head-cell action"></td>
              </th>
            )}

            {/* open:desktop */}
            {activeTab === "Open Stakings" &&
              stakings.map(
                (staking) =>
                  staking.status === "open" && (
                    <tr key={staking?.ref}>
                      <td className="row-cell asset">
                        <span>
                          <img
                            src={`./asseticons/${staking?.asset}.svg`}
                            alt=""
                          />
                          <p>{staking?.asset}</p>
                        </span>
                      </td>
                      <td className="row-cell date">
                        <p> {getTime(new Date() - staking?.date?.toDate())} </p>
                      </td>
                      <td className="row-cell direction">
                        <p>
                          {" "}
                          {Number(staking?.amount).toFixed(2)} {staking?.asset}{" "}
                        </p>
                      </td>
                      <td className="row-cell amount">
                        <p>
                          {staking?.original
                            ? Number(staking?.original).toFixed(2) +
                              " " +
                              staking?.asset
                            : Number(staking?.amount).toFixed(2) +
                              " " +
                              staking?.asset}
                        </p>
                      </td>
                      <td className="row-cell entry">
                        <p>
                          {staking.affiliates && staking.affiliates[userData.id]
                            ? Number(
                                staking.affiliates[userData.id]?.investedAmount
                              ).toFixed(2) +
                              " " +
                              staking.asset
                            : "-"}
                        </p>
                      </td>
                      <td className="row-cell pl">
                        <div className="status_box active">
                          <p>Running</p>
                        </div>
                      </td>
                      <td className="row-cell action">
                        <button onClick={() => handleInvest(staking)}>
                          <p>Invest</p>
                        </button>
                      </td>
                    </tr>
                  )
              )}

            {/* closed:: desktop */}
            {activeTab === "Closed Stakings" &&
              stakings.map(
                (staking) =>
                  staking.status === "closed" && (
                    <tr key={staking?.ref}>
                      <td className="row-cell asset">
                        <span>
                          <img
                            src={`./asseticons/${staking?.asset}.svg`}
                            alt=""
                          />
                          <p>{staking?.asset}</p>
                        </span>
                      </td>
                      <td className="row-cell date">
                        <p> {getTime(new Date() - staking?.date?.toDate())} </p>
                      </td>
                      <td className="row-cell direction">
                        <p>
                          {" "}
                          {Number(staking?.amount).toFixed(2)} {staking?.asset}{" "}
                        </p>
                      </td>
                      <td className="row-cell amount">
                        <p>
                          {" "}
                          {staking?.original
                            ? Number(staking?.original).toFixed(2) +
                              " " +
                              staking?.asset
                            : Number(staking?.amount).toFixed(2) +
                              " " +
                              staking?.asset}
                        </p>
                      </td>
                      <td className="row-cell entry">
                        <p>
                          {staking.affiliates && staking.affiliates[userData.id]
                            ? Number(
                                staking.affiliates[userData.id]?.investedAmount
                              ).toFixed(2) +
                              " " +
                              staking.asset
                            : "-"}
                        </p>
                      </td>
                      <td className="row-cell pl">
                        <div className="status_box inactive">
                          <p>Ended</p>
                        </div>
                      </td>
                      <td className="row-cell action">
                        {/* <button onClick={() => handleInvest(staking)}>
                          <p>Invest</p>
                        </button> */}
                      </td>
                    </tr>
                  )
              )}
          </TradesTableDesktop>

          {/* mobile */}
          <TradesTableMobile>
            {/* open : mobile*/}
            {activeTab === "Open Stakings" &&
              stakings.map(
                (staking) =>
                  staking.status === "open" && (
                    // <div>
                    <div className="table-cell active" key={staking.ref}>
                      <div className="top">
                        <button onClick={() => handleInvest(staking)}>
                          <p>Invest</p>
                        </button>
                      </div>
                      <div className="bottom">
                        <span>
                          <p className="title">Asset</p>
                          <div className="asset">
                            <img
                              src={`./asseticons/${staking?.asset}.svg`}
                              alt=""
                            />
                            <p>{staking?.asset}</p>
                          </div>
                        </span>
                        <span>
                          <p className="title">Date</p>
                          <p className="detail">
                            {" "}
                            {getTime(new Date() - staking?.date?.toDate())}{" "}
                          </p>
                        </span>
                        <span>
                          <p className="title">Amount</p>
                          <p className="detail">
                            {staking?.amount} {staking.asset}
                          </p>
                        </span>
                        <span>
                          <p className="title">{`${user.userRef?.firstname}'s Amount`}</p>
                          <p className="detail">
                            {staking?.original
                              ? Number(staking?.original).toFixed(2) +
                                " " +
                                staking?.asset
                              : Number(staking?.amount).toFixed(2) +
                                " " +
                                staking?.asset}
                          </p>
                        </span>
                        <span>
                          <p className="title">Your Amount</p>
                          <p className="detail">
                            {staking.affiliates &&
                            staking.affiliates[userData.id]
                              ? Number(
                                  staking.affiliates[userData.id]
                                    ?.investedAmount
                                ).toFixed(2) +
                                " " +
                                staking.asset
                              : "-"}
                          </p>
                        </span>
                      </div>
                    </div>
                  )
                // </div>
              )}

            {/* closed : mobile */}
            {activeTab === "Closed Stakings" &&
              stakings.map(
                (staking) =>
                  staking.status === "closed" && (
                    // <div>
                    <div className="table-cell active" key={staking.ref}>
                      <div className="top">
                        {/* <button onClick={() => handleInvest(staking)}>
                          <p>Invest</p>
                        </button> */}
                      </div>
                      <div className="bottom">
                        <span>
                          <p className="title">Asset</p>
                          <div className="asset">
                            <img
                              src={`./asseticons/${staking?.asset}.svg`}
                              alt=""
                            />
                            <p>{staking?.asset}</p>
                          </div>
                        </span>
                        <span>
                          <p className="title">Date</p>
                          <p className="detail">
                            {" "}
                            {getTime(new Date() - staking?.date?.toDate())}{" "}
                          </p>
                        </span>
                        <span>
                          <p className="title">Amount</p>
                          <p className="detail">
                            {staking?.amount} {staking.asset}
                          </p>
                        </span>
                        <span>
                          <p className="title">{`${user.userRef?.firstname}'s Amount`}</p>
                          <p className="detail">
                            {staking?.original
                              ? Number(staking?.original).toFixed(2) +
                                " " +
                                staking?.asset
                              : Number(staking?.amount).toFixed(2) +
                                " " +
                                staking?.asset}
                          </p>
                        </span>
                        <span>
                          <p className="title">Your Amount</p>
                          <p className="detail">
                            {staking.affiliates &&
                            staking.affiliates[userData.id]
                              ? Number(
                                  staking.affiliates[userData.id]
                                    ?.investedAmount
                                ).toFixed(2) +
                                " " +
                                staking.asset
                              : "-"}
                          </p>
                        </span>
                      </div>
                    </div>
                  )
                // </div>
              )}
          </TradesTableMobile>
        </div>
      )}
    </Table>
  );
};

const Table = styled.div`
  background-color: #151823;
  position: relative;
  border-radius: 12px;
  height: 100%;

  .status_box {
    padding: 4px 8px;
    border: 1px solid #c6f6d8;
    background-color: #f0fdf4;
    border-radius: 6px;
    max-width: max-content;
    display: flex;
    gap: 4px;
    align-items: center;
  }

  .status_box p {
    font-size: 14px;
    font-weight: 500;
    color: #15803d;
    line-height: 20px;
  }

  .action_button {
    padding: 4px 24px;
    border: 1px solid #212945;
    background-color: #1b1f2d;
    border-radius: 6px;
    max-width: max-content;
    display: flex;
    gap: 4px;
    align-items: center;
    color: #c6f6d82b;
    cursor: pointer;
    place-self: flex-start;
  }

  .action_button p {
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
  }

  .status_box.active {
    border: 1px solid #c6f6d8;
    background-color: #f0fdf4;
  }

  .status_box.active p {
    color: #15803d;
  }

  .status_box.inactive {
    border: 1px solid #4b3547;
    background-color: #2d202b;
  }

  .status_box.inactive p {
    color: #d55763;
  }

  .status_box.pending {
    border: 1px solid #b2ddff;
    background-color: #eff8ff;
  }

  .status_box.pending p {
    color: #175cd3;
  }
`;

const TradesTableDesktop = styled.table`
  /* display: grid; */
  width: 100%;
  display: none;
  /* max-width: 100%;
  overflow-x: scroll;
  overflow-y: scroll; */

  @media screen and (min-width: 1000px) {
    display: block;
  }

  th {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #212945;
  }

  tr {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .head-cell {
    padding: 0px 24px;
    height: 44px;
    white-space: nowrap;
    text-align: left;
    display: grid;
    align-content: center;
    color: #bac2de;
    font-size: 14px;
    font-weight: 600;
  }

  .row-cell {
    padding: 0px 24px;
    height: 72px;
    white-space: nowrap;
    text-align: left;
    display: grid;
    align-content: center;
    color: white;
  }

  .asset {
    max-width: 120px;
    width: 100%;
  }

  .asset span {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  .asset span img {
    width: 20px;
    height: 20px;
  }

  .asset span p {
    font-size: 14px;
    font-weight: 600;
    color: white;
  }

  .date {
    max-width: 120px;
    width: 100%;
  }

  .direction {
    max-width: 120px;
    width: 100%;
  }

  .amount {
    max-width: 200px;
    width: 100%;
  }

  .entry {
    max-width: 200px;
    width: 100%;
  }

  .pl {
    max-width: 120px;
    width: 100%;
  }

  .action {
    max-width: 120px;
    width: 100%;
    /* background-color: yellow; */
    display: grid;
    justify-content: start;
  }

  .action button {
    padding: 8px 16px;
    color: white;
    background-color: transparent;
    border: 1px solid #212945;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }

  .action button:hover {
    background-color: rgba(27, 31, 45);
    border: 1px solid rgba(27, 31, 45);
  }
`;

const TradesTableMobile = styled.div`
  display: none;

  @media screen and (max-width: 1000px) {
    display: block;
  }

  .table-cell {
    padding: 24px;
    cursor: pointer;
  }

  .table-cell.active .bottom {
    display: block;
  }

  .table-cell:hover {
    background-color: #1b1f2d;
  }

  .top {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .top span {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  .top img {
    width: 20px;
    height: 20px;
  }

  .top p {
    font-size: 16px;
    font-weight: 600;
    color: white;
  }

  .top button {
    padding: 8px 16px;
    color: white;
    background-color: transparent;
    border: 1px solid #212945;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }

  .top button:hover {
    background-color: rgba(27, 31, 45);
    border: 1px solid rgba(27, 31, 45);
  }

  .bottom {
    padding-top: 8px;
    box-sizing: border-box;
    display: none;
  }

  .bottom span {
    margin-top: 12px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }

  .title {
    color: #bac2de;
  }

  .detail {
    color: white;
    font-weight: 500;
  }

  /* .detail.asset {
    max-width: 120px;
    width: 100%;
  } */

  .asset {
    display: flex;
    gap: 6px;
    align-items: center;
  }

  .asset img {
    width: 20px;
    height: 20px;
  }

  .asset p {
    font-size: 14px;
    font-weight: 600;
    color: white;
  }
`;

export default StakingsAffiliateTable;
