import { useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { styled } from "styled-components";
import {
  DropDownBoxWithIcon,
  FullButton,
  MiniAmountBoxFull,
  TextBox,
} from "../styled/input/Input";
import Toast from "../hooks/Toast";
import { useNavigate } from "react-router-dom";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { db, storage } from "../firebase/firebase";
import { formatFileSize, formatter, formatterZero } from "../utils/utils";
import { useDropzone } from "react-dropzone";
import DepositModal from "./DepositModal";
import { siteSettings } from "../static";
import { doc, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";
import emailjs from "@emailjs/browser";
import CircularLoader from "../styled/loaders/CircularLoader";

const AffiliateModal = ({ open, settings, user, prices, applied }) => {
  const { showAffiliateModal, setShowAffiliateModal } = open;
  const { hasAppliedForAffiliate, setHasAppliedForAffiliate } = applied;
  const navigate = useNavigate();

  const { id } = user;

  const [selected, setSelected] = useState(undefined);

  // "Use a referral code"
  const options = ["Sign up for an affiliate account"];

  useEffect(() => {
    if (user && user.affiliateApplied) {
      setHasAppliedForAffiliate(true);
      setShowAffiliateModal(false);
    }
  }, [user]);

  // copy function
  function copyValue(value, type) {
    navigator.clipboard.writeText(value);
    setToastType("success");
    // setToastMessage("Copied " + method + " " + type);
    setOpenToast(true);
  }

  // toast
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(" ");
  const [toastType, setToastType] = useState(undefined);

  //   image
  const [imageName, setImageName] = useState("");
  const [imageLink, setImageLink] = useState("");
  const [imageSize, setImageSize] = useState(undefined);
  const [imageType, setImageType] = useState(undefined);
  const imageRef = useRef();
  function handleImageURL(e) {
    const file = e.target.files[0];

    if (file) {
      setImageLink(file);
      setImageName(file.name);
      setImageSize(formatFileSize(file.size));
      setImageType(file.type?.slice(file.type?.indexOf("/") + 1));
    }
  }

  function handleImageChange() {
    imageRef.current.click();
  }

  const { getRootProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg", ".png", ".svg", ".gif"],
    },
    onDrop: (acceptedFiles) => {
      acceptedFiles.map((file) => {
        setImageLink(file);
        setImageName(file.name);
        setImageSize(formatFileSize(file.size));
        setImageType(file.type?.slice(file.type?.indexOf("/") + 1));
      });
    },
  });

  //   qr

  // show QR
  const [openModal, setOpenModal] = useState(false);
  const [displayedMethod, setDisplayedMethod] = useState(undefined);
  const [displayedAddress, setDisplayedAddress] = useState(undefined);
  const [displayedQR, setDisplayedQR] = useState(undefined);
  function showQRCode() {
    setDisplayedAddress(settings.paymentAddress);
    setDisplayedQR(settings.paymentQr);
    setDisplayedMethod(settings.paymentCurrency);
    setOpenModal(true);
  }

  //   db => affiliates
  //   db => affiliateDeposits
  //   db => affiliateWithdrawals

  // sign up
  const [isSigningUp, setIsSigningUp] = useState(false);
  async function signUpDefault() {
    handlePayment();
  }

  // send affilate deposit request
  function handlePayment() {
    setIsSigningUp(true);
    submitProof();
  }

  async function submitProof() {
    if (imageLink) {
      const storageRef = ref(storage, imageName + new Date());
      const uploadTask = uploadBytesResumable(storageRef, imageLink);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              // console.log("Upload is paused");
              break;
            case "running":
              // console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
          setIsSigningUp(false);
          setToastType("error");
          setToastMessage("Failed to sign up. Please try again later.");
          setOpenToast(true);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            // console.log("File available at", downloadURL);
            submitDeposit(downloadURL);
          });
        }
      );
    }
  }

  async function submitDeposit(url) {
    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      id.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    await setDoc(doc(db, "affiliateDeposits", str), {
      ref: str,
      user: id,
      amount: Number(settings.paymentAmount),
      asset: settings.paymentCurrency,
      totalInUSD: Number(settings.paymentAmount),
      date: serverTimestamp(),
      status: "pending",
      class: "Affiliate",
      type: "manual",
      link: url,
      userRef: {
        name: user.firstname + " " + user.lastname,
        photo: user.photoURL ? user.photoURL : null,
        admin: user.admin,
        email: user.email,
      },
    })
      .then(() => {
        sendAdminNotification(str, url);
        sendAdminEmail();
      })
      .catch((error) => {
        setIsSigningUp(false);
        setToastType("error");
        setToastMessage("Failed to sign up. Please try again later.");
        setOpenToast(true);
      });
  }

  async function sendAdminEmail() {
    const params = {
      to_name: "Admin",
      details: `A new affiliate deposit request was submitted on your platform.`,
      action_name: "Deposit",
      logo_link: `${siteSettings.ogLink}/logo.png`,
      to_login: `${siteSettings.link}`,
      from_name: `${siteSettings.name}`,
      to_email: `${siteSettings.supportEmail}`,
    };

    emailjs
      .send("service_69ssaol", "template_fwhr0oo", params, "9IOr2_lHheTH7RW1k")
      .then(() => {})
      .catch((error) => {});
  }

  // admin notified of deposit
  async function sendAdminNotification(ref, url) {
    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      id.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    await setDoc(doc(db, "adminNotifications", str), {
      ref: str,
      type: "Deposit",
      message:
        "Made an affiliate deposit of " +
        formatterZero.format(Number(settings.paymentAmount)),
      user: id,
      read: false,
      date: serverTimestamp(),
      userRef: {
        name: user.firstname + " " + user.lastname,
        photo: user.photoURL ? user.photoURL : null,
        email: user.email,
      },
      depositRef: {
        type: "manual",
        class: "Affiliate",
        link: url,
        ref,
      },
    })
      .then(() => {
        sendAffiliateRequest();
      })
      .catch((error) => {
        setIsSigningUp(false);
        setToastType("error");
        setToastMessage("Failed to sign up. Please try again later.");
        setOpenToast(true);
      });
  }

  //   send affiliate request
  async function sendAffiliateRequest() {
    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      id.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    await setDoc(doc(db, "affiliates", id), {
      id,
      signUpDate: serverTimestamp(),
      status: "pending",
      referral: null,
      referralCode: str,
      followers: [],
      following: [],
      affiliateTag: null,
      settings: {
        fakeFollowers: false,
        fakeFollowing: false,
        tag: null,
        hideFollowers: false,
        hideFollowing: false,
      },
      userRef: {
        firstname: user.firstname,
        lastname: user.lastname,
        photo: user.photoURL ? user.photoURL : null,
        admin: user.admin,
        email: user.email,
      },
    })
      .then(() => {
        setAppliedStatus();
        // noti ccan send here
      })
      .catch((error) => {
        setIsSigningUp(false);
        setToastType("error");
        setToastMessage("Failed to sign up. Please try again later.");
        setOpenToast(true);
      });
  }

  async function setAppliedStatus() {
    const profile = doc(db, "users", id);
    await updateDoc(profile, {
      affiliateApplied: true,
    })
      .then(() => {
        sendAdminEmailRequest();
        // sendAdminNotificationRequest(ref);
      })
      .catch((error) => {});
  }

  async function sendAdminEmailRequest() {
    const params = {
      to_name: "Admin",
      details: `A user signed up for affiliate on your platform.`,
      action_name: "Affiliate",
      logo_link: `${siteSettings.ogLink}/logo.png`,
      to_login: `${siteSettings.link}`,
      from_name: `${siteSettings.name}`,
      to_email: `${siteSettings.supportEmail}`,
    };

    emailjs
      .send("service_69ssaol", "template_fwhr0oo", params, "9IOr2_lHheTH7RW1k")
      .then(() => {})
      .catch((error) => {});
  }

  // admin notified of deposit
  async function sendAdminNotificationRequest(ref) {
    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      id.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    await setDoc(doc(db, "adminNotifications", str), {
      ref: str,
      type: "Deposit",
      message: "A user " + user.firstname + " signed up for affiliate",
      user: id,
      read: false,
      date: serverTimestamp(),
      userRef: {
        name: user.firstname + " " + user.lastname,
        photo: user.photoURL ? user.photoURL : null,
        email: user.email,
      },
      affiliateRef: {
        class: "Affiliate",
        ref,
      },
    })
      .then(() => {
        setIsSigningUp(false);
        setToastType("success");
        setToastMessage("Request sucessfully sent");
        setOpenToast(true);
        setTimeout(() => {
          setShowAffiliateModal(false);
        }, 400);
      })
      .catch((error) => {
        setIsSigningUp(false);
        setToastType("error");
        setToastMessage("Failed to sign up. Please try again later.");
        setOpenToast(true);
      });
  }

  //   use referral code
  const [referralCode, setReferralCode] = useState("");
  async function signUpCode() {
    // send sign up request to admin
    // send admin notification
    // send user notification & or email
    // set userData status to applied (pre-approval)
  }

  return (
    <>
      {openToast && (
        <Toast
          open={{ openToast, setOpenToast }}
          message={toastMessage}
          type={toastType}
        />
      )}

      {openModal && (
        <DepositModal
          open={{ openModal, setOpenModal }}
          qr={displayedQR}
          address={displayedAddress}
          method={displayedMethod}
        />
      )}

      <Modal
        open={showAffiliateModal}
        onClose={() => navigate("/dashboard")}
        style={{
          display: "flex",
          placeContent: "center",
          zIndex: "10001",
          padding: "12px",
        }}
      >
        <ModalStandard className="scrollbar-hide">
          <div className="modal_top">
            <p>{selected ? selected : "Affiliate"}</p>

            <svg
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => navigate("/dashboard")}
              style={{ cursor: "pointer" }}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.8647 0.366365C12.3532 -0.122122 13.1451 -0.122122 13.6336 0.366365C14.1221 0.854853 14.1221 1.64685 13.6336 2.13533L8.88929 6.87968L13.8743 11.8647C14.3628 12.3532 14.3628 13.1451 13.8743 13.6336C13.3858 14.1221 12.5938 14.1221 12.1053 13.6336L7.12032 8.64864L2.13533 13.6336C1.64685 14.1221 0.854853 14.1221 0.366366 13.6336C-0.122122 13.1451 -0.122122 12.3532 0.366366 11.8647L5.35136 6.87968L0.607014 2.13533C0.118527 1.64685 0.118527 0.854853 0.607014 0.366365C1.0955 -0.122122 1.8875 -0.122122 2.37598 0.366365L7.12032 5.11071L11.8647 0.366365Z"
                fill="#858DAD"
              />
            </svg>
          </div>

          {!selected && (
            <div className="modal_content">
              <div className="top">
                <div
                  style={{
                    marginTop: "32px",
                    fontSize: "14px",
                    padding: "10px 16px",
                    border: "1px solid #222739",
                    borderRadius: "12px",
                    color: "#d4dcf7",
                    lineHeight: "24px",
                    fontWeight: "500",
                  }}
                >
                  <p>
                    You are currently not signed up for an affiliate account.
                    Choose an option below to get started{" "}
                    {/* <strong>Or scan the QR Code below</strong> */}
                  </p>
                </div>
                <br />
              </div>

              <div
                className="buttons"
                style={{
                  display: "grid",
                  gap: "12px",
                  alignItems: "center",
                }}
              >
                {options.map((opt) => (
                  <button
                    key={opt}
                    onClick={() => setSelected(opt)}
                    style={{
                      borderRadius: "8px",
                      border: "none",
                      backgroundColor: "#0C6CF2",
                      color: "white",
                      width: "100%",
                      placeSelf: "center",
                      cursor: "pointer",
                    }}
                    className="button"
                  >
                    <p
                      style={{
                        fontSize: "16px",
                        padding: "12px",
                        fontWeight: "600",
                        fontFamily: "Inter",
                      }}
                    >
                      {opt}
                    </p>
                  </button>
                ))}
              </div>
            </div>
          )}

          {selected === "Sign up for an affiliate account" && (
            <div className="modal_content full">
              <div className="top">
                <span
                  className="go_back"
                  onClick={() => setSelected(undefined)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                    />
                  </svg>

                  <p>Back to menu</p>
                </span>
              </div>

              <br />

              <TextBox className="scrollbar-hide">
                <label htmlFor="address">Your Name:</label>
                <br />
                <input
                  type="text"
                  placeholder={user?.firstname + " " + user?.lastname}
                  value={user?.firstname + " " + user?.lastname}
                  defaultValue={user?.firstname + " " + user?.lastname}
                  disabled
                />
              </TextBox>

              <br />

              {!settings.requestPaymentForAffiliate && (
                <>
                  <br />

                  <TextBox className="scrollbar-hide">
                    <label htmlFor="address">Your email:</label>
                    <br />
                    <input
                      type="text"
                      placeholder={user?.email}
                      value={user?.email}
                      defaultValue={user?.email}
                      disabled
                    />
                  </TextBox>
                </>
              )}

              <br />

              {settings.requestPaymentForAffiliate && (
                <div className="deposit_bottom">
                  <div
                    style={{
                      fontSize: "14px",
                      padding: "10px 16px",
                      border: "1px solid #222739",
                      borderRadius: "12px",
                      color: "#d4dcf7",
                      lineHeight: "24px",
                      fontWeight: "500",
                    }}
                  >
                    <p>
                      Kindly copy and make your deposit of{" "}
                      {formatterZero.format(Number(settings.paymentAmount))}{" "}
                      into the provided address and tap the sign up button.{" "}
                      {settings.paymentQr && (
                        <strong>Or scan the QR Code below</strong>
                      )}
                    </p>
                  </div>

                  <br />

                  <MiniAmountBoxFull
                    className={"amount_box"}
                    // amountError ? "amount_box error" :
                  >
                    <div className="label">
                      <p>Address: </p>
                      <img
                        src="./assets/misc/info.svg"
                        alt=""
                        className="error_inform"
                        id="popcorn"
                      />
                    </div>

                    <div className="wrapper">
                      <input
                        type="text"
                        placeholder={settings.paymentAddress}
                        value={settings.paymentAddress}
                        disabled
                        onClick={() =>
                          copyValue(
                            settings.paymentAddress,
                            settings.paymentAddress + " address"
                          )
                        }
                      />

                      <span
                        className="asset"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          copyValue(settings.paymentAddress, " address")
                        }
                      >
                        <p>Copy</p>
                      </span>
                    </div>
                  </MiniAmountBoxFull>

                  <br />

                  {settings.paymentQr && (
                    <p
                      style={{
                        color: "#0c6ef2",
                        // marginTop: "8px",
                        maxWidth: "max-content",
                        marginLeft: "auto",
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                      onClick={() => showQRCode()}
                    >
                      Or tap to reveal QR Code
                    </p>
                  )}

                  <DropBoxStandard>
                    <div className="box_item" style={{ marginTop: " 24px" }}>
                      <p className="label">Payment proof:</p>

                      <button
                        className={
                          isSigningUp ? "upload_box disabled" : "upload_box"
                        }
                        onClick={handleImageChange}
                        disabled={isSigningUp}
                        accept="image/png, image/gif, image/jpeg"
                      >
                        <>
                          <span className="upload_icon">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.66699 13.3333L10.0003 10M10.0003 10L13.3337 13.3333M10.0003 10V17.5M16.667 13.9524C17.6849 13.1117 18.3337 11.8399 18.3337 10.4167C18.3337 7.88536 16.2816 5.83333 13.7503 5.83333C13.5682 5.83333 13.3979 5.73833 13.3054 5.58145C12.2187 3.73736 10.2124 2.5 7.91699 2.5C4.46521 2.5 1.66699 5.29822 1.66699 8.75C1.66699 10.4718 2.3632 12.0309 3.48945 13.1613"
                                stroke="#BAC2DE"
                                stroke-width="1.66667"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                          <span>
                            <p>Click to upload</p>
                          </span>
                          <p className="allowed">
                            SVG, PNG, JPG or GIF (max. 10 MB)
                          </p>
                          <input
                            onChange={handleImageURL}
                            type="file"
                            style={{
                              opacity: "0",
                              position: "absolute",
                              pointerEvents: "none",
                            }}
                            ref={imageRef}
                            accept="image/png, image/gif, image/jpeg, image/svg"
                          />
                        </>
                      </button>

                      {imageLink && (
                        <div className="file_box">
                          <div className="left display_box">
                            <span>
                              <svg
                                width="32"
                                height="40"
                                viewBox="0 0 32 40"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M28 39.25H4C2.20507 39.25 0.75 37.7949 0.75 36V4C0.75 2.20508 2.20508 0.75 4 0.75H20C20.1212 0.75 20.2375 0.798159 20.3232 0.883883L31.1161 11.6768C31.2018 11.7625 31.25 11.8788 31.25 12V36C31.25 37.7949 29.7949 39.25 28 39.25Z"
                                  fill="white"
                                  stroke="#D0D5DD"
                                  stroke-width="1.5"
                                />
                                <path
                                  d="M20 0.5V8C20 10.2091 21.7909 12 24 12H31.5"
                                  stroke="#D0D5DD"
                                  stroke-width="1.5"
                                />
                              </svg>
                            </span>
                            <div className="detail_wrapper">
                              <p style={{ textTransform: "uppercase" }}>
                                {imageType}
                              </p>
                            </div>
                          </div>
                          <div className="right">
                            <div className="details">
                              <span>
                                <p className="file_name">{imageName}</p>
                                <p className="file_size">{imageSize}</p>
                              </span>
                              {/* <img src="./assets/settings/trash.svg" alt="" /> */}
                            </div>
                          </div>
                        </div>
                      )}

                      <br />
                    </div>
                  </DropBoxStandard>
                </div>
              )}

              <br />

              <div
                className="buttons"
                style={{
                  display: "flex",
                  gap: "12px",
                  alignItems: "center",
                }}
              >
                <FullButton
                  onClick={signUpDefault}
                  disabled={isSigningUp || (settings.paymentQr && !imageLink)}
                  className={
                    (settings.paymentQr && !imageLink) ||
                    (isSigningUp && "disabled")
                  }
                >
                  {isSigningUp ? (
                    <div style={{ padding: "8px" }}>
                      <CircularLoader bg="#cccccc" size="28" color="#ffffff" />
                    </div>
                  ) : (
                    <p>Sign up</p>
                  )}
                </FullButton>
              </div>
            </div>
          )}

          {selected === "Use a referral code" && (
            <div className="modal_content">
              <div className="top">
                <span
                  className="go_back"
                  onClick={() => setSelected(undefined)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                    />
                  </svg>

                  <p>Back to menu</p>
                </span>
              </div>

              <br />

              <TextBox className="scrollbar-hide">
                <label htmlFor="address">Your Name:</label>
                <br />
                <input
                  type="text"
                  placeholder={user?.firstname + " " + user?.lastname}
                  value={user?.firstname + " " + user?.lastname}
                  defaultValue={user?.firstname + " " + user?.lastname}
                  disabled
                />
              </TextBox>

              <>
                <br />

                <TextBox className="scrollbar-hide">
                  <label htmlFor="address">Your email:</label>
                  <br />
                  <input
                    type="text"
                    placeholder={user?.email}
                    value={user?.email}
                    defaultValue={user?.email}
                    disabled
                  />
                </TextBox>
              </>

              <br />

              <TextBox className="scrollbar-hide">
                <label htmlFor="address">Referral code:</label>
                <br />
                <input type="text" placeholder="Your referral code" />
              </TextBox>

              <br />
              <br />
              <div
                className="buttons"
                style={{
                  display: "flex",
                  gap: "12px",
                  alignItems: "center",
                }}
              >
                <button
                  style={{
                    borderRadius: "8px",
                    border: "none",
                    backgroundColor: "#0C6CF2",
                    color: "white",
                    width: "100%",
                    placeSelf: "center",
                    cursor: "pointer",
                  }}
                  className="button"
                >
                  <p
                    style={{
                      fontSize: "16px",
                      padding: "12px",
                      fontWeight: "600",
                      fontFamily: "Inter",
                    }}
                  >
                    Sign up
                  </p>
                </button>
              </div>
            </div>
          )}
        </ModalStandard>
      </Modal>
    </>
  );
};

const ModalStandard = styled.div`
  background-color: #151823;
  border-radius: 12px;
  max-width: 430px;
  place-self: center;
  width: 100%;
  border: 1px solid transparent;
  z-index: 10001;

  .bottom button {
    cursor: pointer;
    width: 100%;
    background-color: #0c6cf2;
    padding: 12px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
    margin-top: 32px;
    font-family: "Inter";
  }

  .bottom button:hover {
    background-color: #0a57c2;
  }

  .modal_top {
    color: white;
    font-size: 16px;
    font-weight: 600;
    background-color: #1b1f2d;
    width: 100%;
    padding: 14px 30px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    position: sticky;
    top: 0;
    z-index: 999;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal_content {
    padding: 24px;
  }

  .modal_content .top {
    display: grid;
    gap: 24px;
  }

  .go_back {
    display: flex;
    gap: 6px;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    text-decoration: underline;
    /* background-color: #0a57c218; */
    max-width: max-content;
    border: 4px;
    padding: 8px 12px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  .go_back p {
  }

  .go_back:hover {
    color: #0c6cf2;
  }

  .go_back svg {
    width: 18px;
    height: 18px;
  }

  .bottom.full {
    margin-top: 32px;
  }

  .bottom.full button {
    cursor: pointer;
    width: 100%;
    background-color: #0c6cf2;
    padding: 12px;
    color: white;
    font-size: 14px;
    font-weight: 600;
    border: none;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
    margin-top: 32px;
  }

  .bottom.full button:hover {
    background-color: #ff3344;
  }

  @media screen and (max-width: 768px) {
    width: 100vw;
    max-width: unset;
    height: fit-content;
    max-height: 90vh;
    position: fixed;
    left: 0;
    bottom: 0;
    padding-bottom: 48px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    overflow-y: scroll;

    .bottom.full button {
      margin: 0;
    }

    .top.full {
      margin-bottom: 52px;
    }

    .bottom.full {
      position: fixed;
      bottom: 0px;
      right: 0px;
      width: 100%;
      padding: 12px 24px;
      height: fit-content;
      background-color: #151823;
      z-index: 999;
      border: none;
      outline: none;
      /* display: none; */
    }
  }

  .modal_top.full {
    color: white;
    font-size: 16px;
    font-weight: 600;
    background-color: #1b1f2d;
    width: 100%;
    padding: 14px 30px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    position: sticky;
    top: 0;
    z-index: 999;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal_content.full {
    padding: 24px;
  }

  .modal_content.full .top {
    display: grid;
    gap: 24px;
  }
`;

const DropBoxStandard = styled.div`
  .file_upload {
    margin-top: 12px;
    max-width: 512px;
    width: 100%;
  }

  .label {
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 500;
    color: #bac2de;
  }

  .display_box {
    position: relative;
  }

  .display_box .detail_wrapper {
    position: absolute;
    left: -10px;
    bottom: 8px;
  }

  .display_box .detail_wrapper p {
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
    padding: 2px 3px;
    border-radius: 2px;
    background-color: #155eef;
    max-width: max-content;
  }

  .upload_box {
    width: 100%;
    padding: 30px;
    display: block;
    border: 1px solid #222739;
    background-color: rgba(27, 31, 45, 0.3);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    position: relative;
    cursor: pointer;
  }

  .upload_box.disabled {
    opacity: 0.7;
    user-select: none;
    pointer-events: none;
    cursor: not-allowed;
  }

  .file_box {
    width: 100%;
    padding: 16px;
    border: 1px solid #222739;
    background-color: rgba(27, 31, 45, 0.3);
    border-radius: 12px;
    margin-top: 16px;
    display: grid;
    grid-template-columns: 40px auto;
    align-items: center;
    gap: 12px;
  }

  .file_box .right {
    display: grid;
    gap: 4px;
  }

  .file_box .right .details {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .bars {
    position: relative;
    width: 100%;
    height: 8px;
    background-color: transparent;
    /* border-radius: 8px; */
  }

  .bars .bar {
    width: 100%;
    position: absolute;
    background-color: #eaecf0;
    height: 100%;
    border-radius: 8px;
    top: 0;
    left: 0;
    box-sizing: border-box;
  }

  .bars .progress {
    /* width: ${(props) => `calc(${props.width} + 1px)`}; */
    position: absolute;
    background-color: #0c6ef2;
    height: 100%;
    border-radius: 8px;
    top: 0;
    left: 0;
    z-index: 33;
    margin-left: -1px;
    box-sizing: border-box;
  }

  .file_box .right .percent {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 500;
    gap: 12px;
    align-items: center;
  }

  .file_box .right span p:nth-child(1) {
    font-size: 16px;
    color: white;
    font-weight: 500;
    line-height: 20px;
  }

  .file_box .right span p:nth-child(2) {
    font-size: 14px;
    color: #bac2de;
    line-height: 20px;
  }

  .upload_box span {
    margin-top: 8px;
    display: flex;
    gap: 4px;
    align-items: center;
  }

  .upload_box span p:nth-child(1) {
    color: #0c6ef2;
    font-weight: 600;
    font-weight: 16px;
    line-height: 20px;
    font-size: 16px;
  }

  .upload_box span p:nth-child(2) {
    color: white;
    font-weight: 16px;
    line-height: 20px;
    font-size: 16px;
    font-weight: 500;
  }

  .upload_icon {
    padding: 10px;
    border: 1px solid #222739;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }

  .upload_box:hover .upload_icon {
    background-color: rgba(27, 31, 45);
  }

  .upload_box .allowed {
    color: #bac2de;
    font-size: 14px;
    line-height: 18px;
  }
`;

export default AffiliateModal;
