import { useContext, useEffect, useState } from "react";
import { context } from "../context/context";
import { styled } from "styled-components";
import { ClickAwayListener, Skeleton, useMediaQuery } from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase/firebase";
import NotificationAlertsUser from "./inner/header/NotificationAlertsUser";
import NotificationAlertsAdmin from "./inner/header/NotificationAlertsAdmin";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import Toast from "../hooks/Toast";

const Header = ({ sidebar }) => {
  const { sidebarHidden, setSidebarHidden } = sidebar;
  const [showNotis, setShowNotis] = useState(false);
  const [showProfileBox, setShowProfileBox] = useState(false);
  const { userData, notiList, dispatch } = useContext(context);
  const [user, loading] = useAuthState(auth);
  const [isLoading, setIsLoading] = useState(true);
  const [tabs, setTabs] = useState([]);
  const mobile = useMediaQuery("(max-width: 768px)");

  const userTabs = ["Inbox"];
  const adminTabs = ["Inbox"];
  // Admin

  // toast
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(" ");
  const [toastType, setToastType] = useState(undefined);

  useEffect(() => {
    if (userData) {
      // checkUserStats(user);
      setIsLoading(false);
    }
    // !userData.blocked
  }, [userData]);

  // useEffect(() => {
  //   if (!loading && user && userData) {
  //     checkUserStats(user);
  //   }
  //   // !userData.blocked
  //   if (!loading && !user) {
  //     setIsLoading(true);
  //   }
  // }, [user, loading, userData]);

  function notificationsLabel(count) {
    if (count === 0) {
      return "no notifications";
    }
    if (count > 99) {
      return "more than 99 notifications";
    }
    return `${count} notifications`;
  }

  function checkUserStats() {
    if (userData?.blocked) {
      setIsLoading(true);
    } else {
      checkAdmin();
    }

    function checkAdmin() {
      if (userData?.admin) {
        setTabs(adminTabs);
        setIsLoading(false);
      } else {
        setTabs(userTabs);
        setIsLoading(false);
      }
    }
  }

  const profileActions = [
    {
      name: "Home",
      icon: "./assets/header/menu-home.svg",
    },
    {
      name: "View profile",
      icon: "./assets/header/menu-user.svg",
    },
    {
      name: "Settings",
      icon: "./assets/header/menu-settings.svg",
    },
    {
      name: "Log out",
      icon: "./assets/header/menu-log-out.svg",
    },
  ];

  const fullName = `${userData.firstname} ${userData.lastname}`;

  // const adminTabs = []
  const [activeTab, setActiveTab] = useState("Inbox");

  const navigate = useNavigate();

  function handleAction(action) {
    switch (action.name) {
      case "Home":
        setShowProfileBox(false);
        navigate("/dashboard");
        break;
      case "View profile":
        setShowProfileBox(false);
        navigate("/settings");
        break;
      case "Settings":
        setShowProfileBox(false);
        navigate("/settings");
        break;
      case "Log out":
        signOut(auth);
        dispatch({ type: "logout" });
        setShowProfileBox(false);
        navigate("/login");
        setToastType("success");
        setToastMessage("Logged out");
        setOpenToast(true);
        break;
      default:
        break;
    }
  }

  return (
    <>
      {openToast && (
        <Toast
          open={{ openToast, setOpenToast }}
          message={toastMessage}
          type={toastType}
        />
      )}

      <HeaderStandard>
        {/* <span className="header_left"> */}
        {!mobile && (
          <>
            {isLoading ? (
              <>
                <span>
                  <img src="./assets/misc/menu.svg" alt="" className="burger" />
                </span>
                {/* </span> */}
                <div className="header_right">
                  <div style={{ position: "relative" }}>
                    <img
                      src="./assets/header/bell_standard.svg"
                      alt=""
                      className="bell"
                    />
                  </div>

                  {/* User */}
                  <div style={{ position: "relative" }}>
                    <Skeleton
                      height={40}
                      width={40}
                      variant="circular"
                      sx={{
                        backgroundColor: "rgba(27, 31, 45)",
                      }}
                    />
                  </div>
                </div>

                <div className="cover"></div>
              </>
            ) : (
              <>
                <img
                  src="./assets/misc/menu.svg"
                  alt=""
                  className="burger"
                  onClick={() => setSidebarHidden(!sidebarHidden)}
                />
                {/* </span> */}
                <div className="header_right">
                  <div style={{ position: "relative" }}>
                    <img
                      src="./assets/header/bell_standard.svg"
                      alt=""
                      className="bell"
                      onClick={() => setShowNotis(!showNotis)}
                    />
                    <IconButton
                      style={{ position: "absolute", right: "0px" }}
                      aria-label={notificationsLabel(notiList.length)}
                    >
                      <Badge
                        badgeContent={notiList.length}
                        color="error"
                      ></Badge>
                    </IconButton>

                    {showNotis && (
                      <ClickAwayListener
                        onClickAway={() => setShowNotis(false)}
                      >
                        <NotiDropdownStandard className="style-4">
                          <div className="top">
                            <p>Notifications</p>
                            <img
                              src="./assets/misc/close.svg"
                              alt=""
                              onClick={() => setShowNotis(!showNotis)}
                            />
                          </div>
                          <div className="nav">
                            <div className="titles">
                              {tabs.map((tab) => (
                                <p
                                  className={
                                    tab === activeTab ? "title active" : "title"
                                  }
                                  key={tab}
                                  onClick={() => setActiveTab(tab)}
                                >
                                  {tab}{" "}
                                </p>
                              ))}
                            </div>
                            <img
                              src="./assets/header/menu-noti-settings.svg"
                              alt=""
                              className="noti_gear"
                              style={{ alignSelf: "start" }}
                            />
                          </div>
                          {activeTab === "Inbox" && (
                            <NotificationAlertsUser
                              id={userData.id}
                              open={{ showNotis, setShowNotis }}
                            />
                          )}
                          {activeTab === "Admin" && <NotificationAlertsAdmin />}
                        </NotiDropdownStandard>
                      </ClickAwayListener>
                    )}

                    {showNotis && <div className="overlay"></div>}
                  </div>

                  {/* User */}
                  <div style={{ position: "relative" }}>
                    {userData && userData.photoURL ? (
                      <img
                        src={userData.photoURL}
                        alt=""
                        className="user"
                        onClick={() => setShowProfileBox(!showProfileBox)}
                      />
                    ) : (
                      <div
                        className="user_circle"
                        onClick={() => setShowProfileBox(!showProfileBox)}
                      >
                        <p>{userData.firstname?.slice(0, 1)}</p>
                      </div>
                    )}

                    {showProfileBox && (
                      <ClickAwayListener
                        onClickAway={() => setShowProfileBox(false)}
                      >
                        <ProfileDropdownStandard>
                          <div className="user_box">
                            {userData && userData.photoURL ? (
                              <img
                                src={userData.photoURL}
                                alt=""
                                className="user"
                                onClick={() =>
                                  setShowProfileBox(!showProfileBox)
                                }
                              />
                            ) : (
                              <div
                                className="user_circle_variant"
                                onClick={() =>
                                  setShowProfileBox(!showProfileBox)
                                }
                              >
                                <p>{userData.firstname?.slice(0, 1)}</p>
                              </div>
                            )}
                            <span className="user_details">
                              {fullName.length <= 21 && (
                                <p className="user_name">
                                  {userData.firstname} {userData.lastname}
                                </p>
                              )}
                              {fullName.length > 21 && (
                                <p className="user_name">
                                  {userData.firstname}
                                </p>
                              )}
                              <p className="user_account">
                                {userData.accountType} account
                              </p>
                            </span>
                          </div>

                          <div className="actions_box">
                            {profileActions.map((action) => (
                              <span onClick={() => handleAction(action)}>
                                <img src={action.icon} alt="" />
                                <p>{action.name}</p>
                              </span>
                            ))}

                            {/* <hr style={{ border: "1px solid #212945" }} /> */}
                          </div>
                        </ProfileDropdownStandard>
                      </ClickAwayListener>
                    )}
                  </div>
                </div>

                <div className="cover"></div>
              </>
            )}
          </>
        )}

        {mobile && (
          <>
            {isLoading ? (
              <>
                <div
                  style={{
                    display: "flex",
                    gap: "16px",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <div class="nav-container burger">
                    <input
                      class="checkbox burger"
                      type="checkbox"
                      name=""
                      id=""
                    />
                    <div class="hamburger-lines">
                      <span class="line line1"></span>
                      <span class="line line2"></span>
                      <span class="line line3"></span>
                    </div>
                  </div>

                  <img
                    src="./logo-full.svg"
                    alt="logo"
                    width="60px"
                    className="logo"
                    style={{ marginLeft: "8px" }}
                  />
                </div>

                {/* </span> */}
                <div className="header_right">
                  <div style={{ position: "relative" }}>
                    <img
                      src="./assets/header/bell_standard.svg"
                      alt=""
                      className="bell"
                    />
                  </div>

                  {/* User */}
                  <div style={{ position: "relative" }}>
                    <Skeleton
                      height={40}
                      width={40}
                      variant="circular"
                      sx={{
                        backgroundColor: "rgba(27, 31, 45)",
                      }}
                    />
                  </div>
                </div>

                <div className="cover"></div>
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    gap: "16px",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <div
                    class="nav-container burger"
                    onClick={() => setSidebarHidden(!sidebarHidden)}
                  >
                    <input
                      class="checkbox burger"
                      type="checkbox"
                      name=""
                      id=""
                      checked={!sidebarHidden}
                      onClick={() => setSidebarHidden(!sidebarHidden)}
                    />
                    <div class="hamburger-lines">
                      <span class="line line1"></span>
                      <span class="line line2"></span>
                      <span class="line line3"></span>
                    </div>
                  </div>
                  {/* <img
                    src="./assets/misc/menu.svg"
                    alt=""
                    className="burger"
                    onClick={() =>
                      setSidebarHidden(sidebarHidden === true ? false : true)
                    }
                  /> */}
                  <img
                    src="./logo-full.svg"
                    alt="logo"
                    width="60px"
                    className="logo"
                    style={{ marginLeft: "8px" }}
                  />
                </div>
                {/* </span> */}
                <div className="header_right">
                  <div style={{ position: "relative" }}>
                    <img
                      src="./assets/header/bell_standard.svg"
                      alt=""
                      className="bell"
                      onClick={() => setShowNotis(!showNotis)}
                    />
                    <IconButton
                      style={{ position: "absolute", right: "0px" }}
                      aria-label={notificationsLabel(notiList.length)}
                    >
                      <Badge
                        badgeContent={notiList.length}
                        color="error"
                      ></Badge>
                    </IconButton>

                    {showNotis && (
                      <ClickAwayListener
                        onClickAway={() => setShowNotis(false)}
                      >
                        <NotiDropdownStandard className="style-4">
                          <div className="top">
                            <p>Notifications</p>
                            <img
                              src="./assets/misc/close.svg"
                              alt=""
                              onClick={() => setShowNotis(!showNotis)}
                            />
                          </div>
                          <div className="nav">
                            <div className="titles">
                              {tabs.map((tab) => (
                                <p
                                  className={
                                    tab === activeTab ? "title active" : "title"
                                  }
                                  key={tab}
                                  onClick={() => setActiveTab(tab)}
                                >
                                  {tab}{" "}
                                </p>
                              ))}
                            </div>
                            <img
                              src="./assets/header/menu-noti-settings.svg"
                              alt=""
                              className="noti_gear"
                              style={{ alignSelf: "start" }}
                            />
                          </div>
                          {activeTab === "Inbox" && (
                            <NotificationAlertsUser
                              id={userData.id}
                              open={{ showNotis, setShowNotis }}
                            />
                          )}
                          {activeTab === "Admin" && <NotificationAlertsAdmin />}
                        </NotiDropdownStandard>
                      </ClickAwayListener>
                    )}

                    {showNotis && <div className="overlay"></div>}
                  </div>

                  {/* User */}
                  <div style={{ position: "relative" }}>
                    {userData && userData.photoURL ? (
                      <img
                        src={userData.photoURL}
                        alt=""
                        className="user"
                        onClick={() => setShowProfileBox(!showProfileBox)}
                      />
                    ) : (
                      <div
                        className="user_circle"
                        onClick={() => setShowProfileBox(!showProfileBox)}
                      >
                        <p>{userData.firstname?.slice(0, 1)}</p>
                      </div>
                    )}

                    {showProfileBox && (
                      <ClickAwayListener
                        onClickAway={() => setShowProfileBox(false)}
                      >
                        <ProfileDropdownStandard>
                          <div className="user_box">
                            {userData && userData.photoURL ? (
                              <img
                                src={userData.photoURL}
                                alt=""
                                className="user"
                                onClick={() =>
                                  setShowProfileBox(!showProfileBox)
                                }
                              />
                            ) : (
                              <div
                                className="user_circle_variant"
                                onClick={() =>
                                  setShowProfileBox(!showProfileBox)
                                }
                              >
                                <p>{userData.firstname?.slice(0, 1)}</p>
                              </div>
                            )}
                            <span className="user_details">
                              {fullName.length <= 21 && (
                                <p className="user_name">
                                  {userData.firstname} {userData.lastname}
                                </p>
                              )}
                              {fullName.length > 21 && (
                                <p className="user_name">
                                  {userData.firstname}
                                </p>
                              )}
                              <p className="user_account">
                                {userData.accountType} account
                              </p>
                            </span>
                          </div>

                          <div className="actions_box">
                            {profileActions.map((action) => (
                              <span onClick={() => handleAction(action)}>
                                <img src={action.icon} alt="" />
                                <p>{action.name}</p>
                              </span>
                            ))}

                            {/* <hr style={{ border: "1px solid #212945" }} /> */}
                          </div>
                        </ProfileDropdownStandard>
                      </ClickAwayListener>
                    )}
                  </div>
                </div>

                <div className="cover"></div>
              </>
            )}
          </>
        )}
      </HeaderStandard>
    </>
  );
};

{
  /*  */
}

const HeaderStandard = styled.div`
  grid-area: header;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #0c0f19;
  padding: 24px 0px;
  position: sticky;
  top: 0;
  z-index: 5000;

  .nav-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 24px;
    width: 24px;
  }

  .nav-container .checkbox {
    position: absolute;

    display: block;
    height: 24px;
    width: 24px;
    left: 0px;
    top: 0px;

    z-index: 5;

    opacity: 0;
    cursor: pointer;
  }

  .nav-container .hamburger-lines {
    display: block;
    height: 22px;
    width: 28px;
    margin-left: 4px;

    position: absolute;
    /* top: 17px; */
    /* left: 20px; */
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .nav-container .hamburger-lines .line {
    display: block;
    height: 3px;
    /* width: 100%; */
    /* border-radius: 10px; */
    background: white;
  }

  .nav-container .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
  }

  .nav-container .hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
  }

  .nav-container .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
  }

  .navbar .menu-items {
    padding-top: 120px;
    box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5);
    height: 100vh;
    width: 100%;
    transform: translate(-150%);
    display: flex;
    flex-direction: column;
    margin-left: -40px;
    padding-left: 50px;
    transition: transform 0.5s ease-in-out;
    text-align: center;
  }

  .nav-container input[type="checkbox"]:checked ~ .menu-items {
    transform: translateX(0);
  }

  .nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
    transform: rotate(45deg);
  }

  .nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
    transform: scaleY(0);
  }

  .nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
    transform: rotate(-45deg);
  }

  .nav-container input[type="checkbox"]:checked ~ .logo {
    display: none;
  }

  .cover {
    position: absolute;
    width: 100vw;
    height: 50px;
    /* background-color: green; */
    padding: 45px;
    top: 0;
    display: none;
  }

  .overlay {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: #0c0f19;
    opacity: 0.9;
    filter: blur(8px);
    -webkit-filter: blur(8px);
    top: 0;
    left: 0;
    z-index: 2;
    display: none;
  }

  @media screen and (max-width: 500px) {
    .overlay {
      display: block;
    }
  }

  .burger {
    cursor: pointer;
    transition: all 0.1s cubic-bezier(0.46, 0.03, 0.52, 0.96);
    /* padding: 8px; */
    visibility: hidden;
    pointer-events: none;
    user-select: none;

    @media screen and (max-width: 768px) {
      visibility: visible;
      pointer-events: all;
      user-select: all;
    }
  }

  .burger:hover {
    background-color: #111522;
    border-radius: 8px;
  }

  .bell {
    cursor: pointer;
    padding: 8px;
  }

  .bell:hover {
    background-color: #151823;
    border-radius: 8px;
  }

  .header_right {
    display: flex;
    gap: 24px;
    align-items: center;
    cursor: pointer;
  }

  .user {
    height: 40px;
    width: 40px;
    border-radius: 100%;
  }

  .user_circle,
  .user_circle_variant {
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: #1b1f2d;
    display: grid;
    color: #bac2de;
    font-weight: 600;
    font-size: 18px;
    display: flex;
  }

  .user_circle p,
  .user_circle_variant p {
    width: 40px;
    text-align: center;
    place-self: center;
  }

  .user_circle_variant {
    background-color: #30374f;
  }
`;

const NotiDropdownStandard = styled.div`
  background-color: #1b1f2d;
  border-radius: 8px;
  position: absolute;
  top: 46px;
  right: 0;
  /* height: 100%; */
  height: 500px;
  color: white;
  /* padding: 4px; */
  min-width: 430px;
  width: 100%;
  overflow: hidden;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  border: 1px solid #2b2f3c;

  .noti_gear {
    padding: 4px;
    display: none;
  }

  .noti_gear:hover {
    background-color: #111522;
    border-radius: 8px;
  }

  @media screen and (max-width: 768px) {
    width: 100vw;
    max-width: unset;
    min-width: unset;
    height: 100vh;
    max-height: unset;
    z-index: 3;
    position: fixed;
    left: 0;
  }

  .top {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .top p {
    font-size: 20px;
    font-weight: 600;
    color: white;
  }

  .nav {
    margin-top: 8px;
    padding: 0px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #212945;
  }

  .nav .titles {
    display: flex;
    gap: 16px;
  }

  .title {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
    color: #bac2de;
  }

  .title.active {
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid #0c6cf2;
    padding-bottom: 8px;
    color: white;
  }

  .alerts {
    /* padding: 32px 24px; */
    /* max-height: 300px; */
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    /* padding: 32px; */
  }

  .alerts .alert:last-child {
    /* padding-bottom: 60px; */
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #212945;
    padding: 16px 24px;
    white-space: nowrap;
    position: absolute;
    /* margin: auto auto; */
    bottom: 0;
    width: 100%;
    font-family: Inter;
    background-color: #1b1f2d;
    z-index: 3;
  }

  @media screen and (max-width: 500px) {
    .bottom {
      position: fixed;
      bottom: 0px;
      right: 0px;
      /* margin-bottom: 56px; */
    }
  }

  .bottom p {
    color: #0c6cf2;
    font-size: 14px;
    font-weight: 600;
  }

  .button {
    padding: 8px 16px;
    background-color: #ff3344;
    color: white;
    font-size: 12px;
    font-weight: 600;
    border: none;
    border-radius: 8px;
    /* font-family: "Inter"; */
  }

  .button p {
    color: white;
  }
`;

const ProfileDropdownStandard = styled.div`
  background-color: #1b1f2d;
  position: absolute;
  top: 46px;
  right: 0;
  border-radius: 8px;
  border: 1px solid #2b2f3c;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  padding: 4px;
  min-width: 270px;
  /* max-width: 300px; */
  width: 100%;

  .user_box {
    display: flex;
    gap: 12px;
    padding: 12px 16px;
  }

  .user_details {
    display: grid;
    white-space: nowrap;
  }

  .user_name {
    font-size: 16px;
    color: white;
    font-weight: 600;
    max-width: 100%;
    text-overflow: hidden;
    overflow: hidden;
  }

  .user_account {
    font-size: 14px;
    color: #bac2de;
  }

  .actions_box span {
    display: flex;
    gap: 8px;
    font-size: 16px;
    font-weight: 500;
    align-items: center;
    color: #bac2de;
    padding: 12px 16px;
    cursor: pointer;
  }

  .actions_box span:hover {
    background-color: #1c233b;
    border-radius: 6px;
  }
`;

export default Header;
