import { styled } from "styled-components";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { MainContainer } from "../styled/templates/MainContainer";
import { MainPage } from "../styled/templates/MainPage";
import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import CircularLoader from "../styled/loaders/CircularLoader";
import { context } from "../context/context";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import AffiliateModal from "../modals/AffiliateModal";
import {
  FormButton,
  FullButton,
  PageButton,
  Search,
} from "../styled/input/Input";
import { CardsWithGrid } from "../styled/templates/CardsWithGrid";
import { formatnumber, formatterZero } from "../utils/utils";
import SingleAffiliate from "./SingleAffiliate";
import Toast from "../hooks/Toast";
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
} from "firebase/firestore";
import WithdrawAffiliateBalanceModal from "../modals/WithdrawAffiliateBalanceModal";

// const scroll = Scroll.animateScroll;

const Affiliate = () => {
  const mobile = useMediaQuery("(max-width: 768px)");
  const [sidebarHidden, setSidebarHidden] = useState(mobile ? true : false);

  const [isLoading, setIsLoading] = useState(true);

  const {
    userData,
    accounts,
    affiliateSettings,
    currentPrices,
    affiliateBalance,
  } = useContext(context);

  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  const { id } = userData;

  // toast
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(" ");
  const [toastType, setToastType] = useState(undefined);

  const [loader, setLoader] = useState(true);
  const [affiliateApproved, setAffiliateApproved] = useState(false);

  const [hasAppliedForAffiliate, setHasAppliedForAffiliate] = useState(false);

  // useEffect(() => {
  //   console.log(userData);
  // }, []);

  useEffect(() => {
    if (!loading && user && userData && accounts) {
      setLoader(false);
      checkUserStats(user);
    }

    if (loading && !user) {
      setLoader(true);
    }

    if (!loading && !user) {
      navigate("/login");
    }
  }, [user, loading]);

  function checkUserStats() {
    if (userData?.blocked) {
      navigate("/login");
      setToastMessage("Your account has been blocked");
      setToastType("error");
      setOpenToast(true);
    } else {
      checkAdmin();
    }
  }

  const [isAdmin, setIsAdmin] = useState(false);

  function checkAdmin() {
    if (userData?.admin) {
      // setUserIsAdmin(true);
      checkAffiliate();
    } else {
      checkAffiliate();
    }
  }

  const [showAffiliateModal, setShowAffiliateModal] = useState(false);

  const [checks, setChecks] = useState(false);

  async function checkAffiliate() {
    const { affiliateApplied, affiliateApproved } = userData;

    if (!affiliateApplied && !affiliateApproved) {
      setLoader(false);
      setShowAffiliateModal(true);
    } else if (affiliateApplied && !affiliateApproved) {
      setLoader(false);
      setHasAppliedForAffiliate(true);
    } else if (affiliateApplied && affiliateApproved) {
      setLoader(true);
      setAffiliateApproved(true);
      setHasAppliedForAffiliate(true);

      // setTimeout(() => {
      //   setChecks(true);
      // }, 400);
      getAffiliates();
    }
  }

  const affiliateTabs = ["Followers", "Following"];
  const [selectedTab, setSelectedTab] = useState("Followers");

  const [selectedUserAffiliate, setSelectedUserAffiliate] = useState(null);

  const [currentUserAffiliate, setCurrentUserAffiliate] = useState(null);

  const [users, setUsers] = useState([]);
  const [userList, setUserList] = useState([]);

  // useEffect(() => {
  //   function getAffiliates() {
  //     const q = query(collection(db, "affiliates"));
  //     const unsubscribe = onSnapshot(q, (querySnapshot) => {
  //       const usersList = [];
  //       const listOfAffiliates = {};
  //       querySnapshot.forEach((doc) => {
  //         usersList.push(doc.data());
  //         listOfAffiliates[doc.id] = doc.data();
  //       });

  //       if (searched) {
  //         setSearched(true)
  //         setAffiliateList(listOfAffiliates);
  //       }
  //       // setUsers(usersList);
  //       // setUserList(usersList);
  //       // }

  //       // if (!searched) {
  //       // }

  //       // setUsers(usersList);
  //       // setAffiliateList(listOfAffiliates);
  //       // setUserList(usersList);
  //       // setTimeout(() => {
  //       //   setLoader(false);
  //       // }, 600);
  //     });
  //   }

  //   if (checks) {
  //     getAffiliates();
  //   }
  // }, [checks]);

  const [affiliateList, setAffiliateList] = useState([]);

  async function getAffiliates() {
    const usersList = [];
    const listOfAffiliates = {};

    const q = query(collection(db, "affiliates"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      usersList.push(doc.data());
      if (doc.id === id) {
        setCurrentUserAffiliate(doc.data());
      }
      listOfAffiliates[doc.id] = doc.data();
      // [doc.id]: doc.data()
    });

    setUsers(usersList);
    setAffiliateList(listOfAffiliates);
    setUserList(usersList);

    // setUserList(usersList);
    // console.log(listOfAffiliates);
    setTimeout(() => {
      setLoader(false);
    }, 500);
  }

  function handleMenuSwitch(value) {
    setSelectedTab(value);
  }

  const fauxAffiliates = {
    FT6B4DVySXTqdnrK1l0lo147qzs2: {
      followers: [],
      following: [],
      status: "active",
      referral: null,
      referralCode: "E65GB79",
      affiliateTag: "Professional",
      id: "FT6B4DVySXTqdnrK1l0lo147qzs2",
      userRef: {
        firstname: "Elon",
        lastname: "Musk",
        photo: "https://xsgames.co/randomusers/assets/avatars/male/34.jpg",
        email: "elonmuskboy@gmail.com",
      },
    },
    BlA1e0wd7bXhXigxcHOfnHqK7m92: {
      followers: [],
      following: ["BlA1e0wd7bXhXigxcHOfnHqK7m92"],
      status: "active",
      referral: null,
      referralCode: "E67GB79",
      affiliateTag: null,
      id: "BlA1e0wd7bXhXigxcHOfnHqK7m92",
      userRef: {
        name: "Melvin Walters",
        photo: "https://xsgames.co/randomusers/assets/avatars/male/45.jpg",
        email: "bigmelvin@gmail.com",
      },
    },
    GKN4BydqfhhjdHIcEeUJusojtRp1: {
      followers: [
        "BlA1e0wd7bXhXigxcHOfnHqK7m92",
        "FT6B4DVySXTqdnrK1l0lo147qzs2",
        "GKN4BydqfhhjdHIcEeUJusojtRp1",
      ],
      following: ["BlA1e0wd7bXhXigxcHOfnHqK7m92"],
      status: "active",
      referral: null,
      referralCode: "E65GB09",
      affiliateTag: null,
      id: "GKN4BydqfhhjdHIcEeUJusojtRp1",
      userRef: {
        name: "Dev",
        photo:
          "https://firebasestorage.googleapis.com/v0/b/finance-v2-e1682.appspot.com/o/9FE5B4D2-BAB0-494D-B5A2-8E5A2C77CFFF.jpegSun%20Jul%2030%202023%2016%3A56%3A25%20GMT%2B0100%20(West%20Africa%20Standard%20Time)?alt=media&token=90a30d1c-0760-49bb-8e56-8d4377d896a9",
        email: "testdev@gmail.com",
      },
    }, // if affiliate.id === id const (currentUserAffiliate)   =  (user opened) = const (selectedUserAffiliate)
  };

  // useEffect(() => {
  //   users.forEach((user) => {
  //     if (user.id === id) {
  //       console.log(user);
  //     }
  //   });
  // }, [users]);

  async function handleViewAffiliate(affiliate) {
    users.forEach((user) => {
      if (user.id === affiliate.id) {
        setSelectedUserAffiliate(user);
      }
    });
  }

  // copy function
  function copyValue(value, type) {
    navigator.clipboard.writeText(value);
    setToastType("success");
    setToastMessage("Copied" + type);
    setOpenToast(true);
  }

  const [searched, setSearched] = useState(false);

  function handleSearchUsers(e) {
    const { value } = e.target;
    let filteredUsers;

    if (value) {
      filteredUsers = userList.filter(
        (users) =>
          users.id != id &&
          users.referralCode.toLowerCase() ===
            String(value.toLowerCase()).replace(/\s/g, "")
      );
      // console.log(Object.values(filteredUsers));

      setSearched(true);
      setUsers(filteredUsers);
    } else {
      setUsers(userList);
      setSearched(false);
    }
  }

  // follow user
  const [isFollowingUser, setIsFollowingUser] = useState(false);
  const [selectedFollowUser, setSelectedFollowUser] = useState(undefined);

  async function handleFollow(user) {
    // selected user flow
    setIsFollowingUser(true);

    const { id, followers } = user;

    setSelectedFollowUser(id);

    let list = [];

    if (followers) {
      followers.forEach((follow) => {
        list.push(follow);
      });
    }

    list.push(userData.id);

    const ref = doc(db, "affiliates", id);
    const index = `followers`;

    await updateDoc(ref, {
      [index]: list,
    })
      .then(() => {
        followSelected(id);
      })
      .catch((error) => {
        setIsFollowingUser(false);
        setSelectedFollowUser(undefined);
        setToastType("error");
        setToastMessage("Failed to follow. Please try again later.");
        setOpenToast(true);
      });
  }

  async function followSelected(user) {
    const { id, following } = currentUserAffiliate;

    let list = [];

    if (following) {
      following.forEach((follow) => {
        list.push(follow);
      });
    }

    list.push(user);

    const ref = doc(db, "affiliates", id);
    const index = `following`;

    await updateDoc(ref, {
      [index]: list,
    })
      .then(() => {
        // setChecks(true);
        // setTimeout(() => {
        //   setChecks(false);
        // }, 500);
        getAffiliates();
        setSelectedFollowUser(undefined);
        setIsFollowingUser(false);
        setToastType("success");
        setToastMessage("User followed successfully");
        setOpenToast(true);
      })
      .catch((error) => {
        setSelectedFollowUser(undefined);
        setIsFollowingUser(false);
        setToastType("error");
        setToastMessage("Failed to follow. Please try again later.");
        setOpenToast(true);
      });
  }

  // unfollow user
  const [isUnfollowingUser, setIsUnfollowingUser] = useState(false);
  const [selectedUnfollowUser, setSelectedUnfollowUser] = useState(undefined);
  async function handleUnfollow(user) {
    const { id, followers } = user;

    setSelectedUnfollowUser(id);

    setIsUnfollowingUser(true);

    const list = followers.filter((follow) => follow !== userData.id);

    const ref = doc(db, "affiliates", id);
    const index = `followers`;

    await updateDoc(ref, {
      [index]: list,
    })
      .then(() => {
        unfollowSelected(id);
      })
      .catch((error) => {
        setSelectedUnfollowUser(undefined);
        setIsUnfollowingUser(false);
        setToastType("error");
        setToastMessage("Failed to unfollow. Please try again later.");
        setOpenToast(true);
      });
  }

  async function unfollowSelected(user) {
    const { id, following } = currentUserAffiliate;

    const list = following.filter((sub) => sub !== user);

    const ref = doc(db, "affiliates", id);
    const index = `following`;

    await updateDoc(ref, {
      [index]: list,
    })
      .then(() => {
        // setChecks(true);
        // setTimeout(() => {
        //   setChecks(false);
        // }, 500);
        getAffiliates();
        setSelectedUnfollowUser(undefined);
        setIsUnfollowingUser(false);
        setToastType("success");
        setToastMessage("User unfollowed successfully");
        setOpenToast(true);
      })
      .catch((error) => {
        setSelectedUnfollowUser(undefined);
        setIsUnfollowingUser(false);
        setToastType("error");
        setToastMessage("Failed to unfollow. Please try again later.");
        setOpenToast(true);
      });
  }

  // withdraw
  const [withdraw, setWithdraw] = useState(false);

  return (
    <MainContainer>
      {openToast && (
        <Toast
          open={{ openToast, setOpenToast }}
          message={toastMessage}
          type={toastType}
        />
      )}

      {withdraw && (
        <WithdrawAffiliateBalanceModal open={{ withdraw, setWithdraw }} />
      )}

      {loader ? (
        <MainPage>
          <CircularLoader
            bg="rgba(12, 108, 243, 0.2)"
            size="44"
            color="#0C6CF2"
          />
        </MainPage>
      ) : (
        <>
          {showAffiliateModal && (
            <AffiliateModal
              open={{ showAffiliateModal, setShowAffiliateModal }}
              settings={affiliateSettings}
              user={userData}
              prices={currentPrices}
              applied={{ hasAppliedForAffiliate, setHasAppliedForAffiliate }}
            />
          )}

          <Sidebar
            selected="Affiliate"
            hidden={{ sidebarHidden, setSidebarHidden }}
          />
          <MainPage className="style-4" id="mainView">
            <Header sidebar={{ sidebarHidden, setSidebarHidden }} />

            <div className="content">
              <div className="main">
                {hasAppliedForAffiliate &&
                  !affiliateApproved &&
                  !selectedUserAffiliate && (
                    <AffiliateContainerStandard>
                      <div className="user_info_affiliate">
                        {/* info */}
                        <div className="user_info_top">
                          {userData.photoURL ? (
                            <img
                              src={userData.photoURL}
                              alt=""
                              srcSet={userData.photoURL}
                            />
                          ) : (
                            <svg
                              style={{ width: "120px" }}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="#fff"
                              class="w-6 h-6"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          )}

                          <span>
                            <p
                              className="user_name"
                              onClick={() =>
                                copyValue(
                                  `${userData.firstname} ${userData.lastname}`,
                                  " name"
                                )
                              }
                            >
                              {userData.firstname} {userData.lastname}
                            </p>

                            <span
                              className="wrapper"
                              onClick={() =>
                                copyValue(userData.email, " email address")
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="#BAC2DE"
                                class="w-6 h-6"
                              >
                                <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                                <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                              </svg>
                              <p className="user_email">{userData.email}</p>
                            </span>
                          </span>
                        </div>

                        {/* buttons */}
                        <div className="pending">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="#F7931A"
                            class="w-6 h-6"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                              clip-rule="evenodd"
                            />
                          </svg>

                          <p>
                            Your affiliate request has been submitted and is
                            currently pending
                          </p>
                        </div>
                      </div>
                    </AffiliateContainerStandard>
                  )}

                {hasAppliedForAffiliate &&
                  affiliateApproved &&
                  !selectedUserAffiliate && (
                    <AffiliateContainerStandard>
                      <div className="user_info_affiliate">
                        {/* info */}
                        <div className="user_info_top">
                          {userData.photoURL ? (
                            <img
                              src={userData.photoURL}
                              alt=""
                              srcSet={userData.photoURL}
                            />
                          ) : (
                            <svg
                              style={{ width: "120px" }}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="#fff"
                              class="w-6 h-6"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          )}

                          <span>
                            <p
                              className="user_name"
                              onClick={() =>
                                copyValue(
                                  `${currentUserAffiliate.userRef?.firstname} ${currentUserAffiliate.userRef?.lastname}`,
                                  " name"
                                )
                              }
                            >
                              {currentUserAffiliate.userRef?.firstname}{" "}
                              {currentUserAffiliate.userRef?.lastname}
                              {currentUserAffiliate.settings?.useTag && (
                                <div className="tags">
                                  <span className="expert_status">
                                    <p>{currentUserAffiliate.settings?.tag}</p>
                                  </span>
                                </div>
                              )}
                            </p>
                            <span className="wrapper">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="#BAC2DE"
                                class="w-6 h-6"
                              >
                                <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                                <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                              </svg>
                              <p
                                className="user_email"
                                onClick={() =>
                                  copyValue(
                                    `${currentUserAffiliate.userRef?.email}`,
                                    " email"
                                  )
                                }
                              >
                                {currentUserAffiliate.userRef?.email}
                              </p>
                            </span>

                            <span className="wrapper">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="#BAC2DE"
                                class="w-6 h-6"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M3 4.875C3 3.839 3.84 3 4.875 3h4.5c1.036 0 1.875.84 1.875 1.875v4.5c0 1.036-.84 1.875-1.875 1.875h-4.5A1.875 1.875 0 0 1 3 9.375v-4.5ZM4.875 4.5a.375.375 0 0 0-.375.375v4.5c0 .207.168.375.375.375h4.5a.375.375 0 0 0 .375-.375v-4.5a.375.375 0 0 0-.375-.375h-4.5Zm7.875.375c0-1.036.84-1.875 1.875-1.875h4.5C20.16 3 21 3.84 21 4.875v4.5c0 1.036-.84 1.875-1.875 1.875h-4.5a1.875 1.875 0 0 1-1.875-1.875v-4.5Zm1.875-.375a.375.375 0 0 0-.375.375v4.5c0 .207.168.375.375.375h4.5a.375.375 0 0 0 .375-.375v-4.5a.375.375 0 0 0-.375-.375h-4.5ZM6 6.75A.75.75 0 0 1 6.75 6h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75A.75.75 0 0 1 6 7.5v-.75Zm9.75 0A.75.75 0 0 1 16.5 6h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75a.75.75 0 0 1-.75-.75v-.75ZM3 14.625c0-1.036.84-1.875 1.875-1.875h4.5c1.036 0 1.875.84 1.875 1.875v4.5c0 1.035-.84 1.875-1.875 1.875h-4.5A1.875 1.875 0 0 1 3 19.125v-4.5Zm1.875-.375a.375.375 0 0 0-.375.375v4.5c0 .207.168.375.375.375h4.5a.375.375 0 0 0 .375-.375v-4.5a.375.375 0 0 0-.375-.375h-4.5Zm7.875-.75a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75a.75.75 0 0 1-.75-.75v-.75Zm6 0a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75a.75.75 0 0 1-.75-.75v-.75ZM6 16.5a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75a.75.75 0 0 1-.75-.75v-.75Zm9.75 0a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75a.75.75 0 0 1-.75-.75v-.75Zm-3 3a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75a.75.75 0 0 1-.75-.75v-.75Zm6 0a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75a.75.75 0 0 1-.75-.75v-.75Z"
                                  clip-rule="evenodd"
                                />
                              </svg>

                              <p
                                className="user_code"
                                onClick={() =>
                                  copyValue(
                                    `${currentUserAffiliate?.referralCode}`,
                                    " referral code"
                                  )
                                }
                              >
                                {currentUserAffiliate?.referralCode}
                              </p>
                            </span>

                            <div className="followers_info">
                              <p>
                                {" "}
                                {currentUserAffiliate.settings?.fakeFollowing
                                  ? formatnumber(
                                      currentUserAffiliate.settings
                                        ?.followingFaux
                                    )
                                  : formatnumber(
                                      currentUserAffiliate.following?.length
                                    )}{" "}
                                Following
                              </p>
                              <p>•</p>
                              <p>
                                {" "}
                                {currentUserAffiliate.settings?.fakeFollowers
                                  ? formatnumber(
                                      currentUserAffiliate.settings
                                        ?.followersFaux
                                    )
                                  : formatnumber(
                                      currentUserAffiliate.followers?.length
                                    )}{" "}
                                Followers
                              </p>
                              <p>•</p>
                              <p>
                                {formatterZero.format(affiliateBalance)} Earned
                              </p>
                            </div>
                          </span>
                        </div>

                        {/* buttons */}
                      </div>

                      <br />

                      {/* Search */}
                      <div className="page_top">
                        <div className="user_info_bottom">
                          <PageButton
                            onClick={() =>
                              copyValue(
                                `${currentUserAffiliate?.referralCode}`,
                                " referral code"
                              )
                            }
                          >
                            <p>Copy code</p>
                          </PageButton>
                          <PageButton onClick={() => setWithdraw(true)}>
                            <p>Withdraw earnings</p>
                          </PageButton>
                        </div>
                        <br />
                        <Search style={{ maxHeight: "max-content" }}>
                          <img src="./assets/misc/search.svg" alt="" />
                          <input
                            type="text"
                            name=""
                            id=""
                            placeholder="Search"
                            onChange={handleSearchUsers}
                          />
                        </Search>
                      </div>

                      {!searched && (
                        <div
                          className="menuSwitch scrollbar-hide"
                          style={{
                            display: "flex",
                            marginTop: "40px",
                            gap: "24px",
                            fontWeight: "500",
                            borderBottom: "1px solid rgba(27, 31, 45, 0.7)",
                            fontSize: "18px",
                            overflowX: "auto",
                            overflowY: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {affiliateTabs.map((tab) => (
                            <div
                              key={tab}
                              style={{
                                padding: "0px 4px",
                                position: "relative",
                                paddingBottom: "24px",
                                cursor: "pointer",
                                transition: "all 0.3s ease-in-out",
                                color: tab !== selectedTab ? "#BAC2DE" : "#fff",
                              }}
                              onClick={() => handleMenuSwitch(tab)}
                            >
                              <p>{tab}</p>
                              {tab === selectedTab && (
                                <div
                                  style={{
                                    width: "100%",
                                    height: "3px",
                                    backgroundColor: "#0C6CF2",
                                    position: "absolute",
                                    bottom: "0",
                                    marginBottom: "0.1px",
                                    transition: "all 0.3s ease-in-out",
                                    zIndex: "99",
                                  }}
                                ></div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}

                      {searched && users.length > 0 && (
                        <CardsWithGrid>
                          {users &&
                            users.length > 0 &&
                            users[0].id !== userData.id && (
                              <AffiliateCard key={users[0].id}>
                                <div className="card_top">
                                  <div className="left">
                                    {!users[0].userRef?.photo ? (
                                      <div className="user_circle">
                                        <p>
                                          {user.userRef?.firstname.slice(0, 1)}
                                        </p>
                                      </div>
                                    ) : (
                                      <img
                                        src={users[0].userRef?.photo}
                                        alt=""
                                        style={{
                                          width: "44px",
                                          height: "44px",
                                          borderRadius: "100%",
                                        }}
                                      />
                                    )}

                                    <div className="info">
                                      <p className="name">
                                        {users[0].userRef?.firstname}{" "}
                                        {users[0].userRef?.lastname}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="card_mid">
                                  <span className="followers">
                                    <p>Followers</p>
                                    <p>
                                      {" "}
                                      {users[0].settings?.fakeFollowers
                                        ? formatnumber(
                                            users[0].settings?.followersFaux
                                          )
                                        : formatnumber(
                                            users[0].followers?.length
                                          )}
                                    </p>
                                  </span>

                                  <span className="following">
                                    <p>Following</p>
                                    <p>
                                      {users[0].settings?.fakeFollowing
                                        ? formatnumber(
                                            users[0].settings?.followingFaux
                                          )
                                        : formatnumber(
                                            users[0].following?.length
                                          )}
                                    </p>
                                  </span>

                                  <span className="referral">
                                    <p>Referral code</p>
                                    <p>{users[0]?.referralCode}</p>
                                  </span>
                                </div>

                                <div className="card_bottom">
                                  <FullButton
                                    className="variant"
                                    onClick={() =>
                                      handleViewAffiliate(users[0])
                                    }
                                  >
                                    <p>View account</p>
                                  </FullButton>

                                  <>
                                    {users[0].followers.includes(
                                      userData.id
                                    ) && (
                                      <FullButton
                                        onClick={() => handleUnfollow(users[0])}
                                        disabled={
                                          isUnfollowingUser &&
                                          selectedUnfollowUser === users[0].id
                                        }
                                        className={
                                          isUnfollowingUser &&
                                          selectedUnfollowUser === users[0].id
                                            ? "delete disabled"
                                            : "delete"
                                        }
                                      >
                                        {isUnfollowingUser &&
                                        selectedUnfollowUser === users[0].id ? (
                                          <div style={{ padding: "8px" }}>
                                            <CircularLoader
                                              bg="#cccccc"
                                              size="28"
                                              color="#ffffff"
                                            />
                                          </div>
                                        ) : (
                                          <p>Unfollow</p>
                                        )}
                                      </FullButton>
                                    )}

                                    {!users[0].followers.includes(
                                      userData.id
                                    ) && (
                                      <FullButton
                                        onClick={() => handleFollow(users[0])}
                                        disabled={
                                          isFollowingUser &&
                                          selectedFollowUser === users[0].id
                                        }
                                        className={
                                          isFollowingUser &&
                                          selectedFollowUser === users[0].id &&
                                          "disabled"
                                        }
                                      >
                                        {isFollowingUser &&
                                        selectedFollowUser === users[0].id ? (
                                          <div style={{ padding: "8px" }}>
                                            <CircularLoader
                                              bg="#cccccc"
                                              size="28"
                                              color="#ffffff"
                                            />
                                          </div>
                                        ) : (
                                          <p>Follow</p>
                                        )}
                                      </FullButton>
                                    )}
                                  </>
                                </div>
                              </AffiliateCard>
                            )}
                        </CardsWithGrid>
                      )}

                      {/* followers */}
                      {!searched && selectedTab === "Followers" && (
                        <CardsWithGrid>
                          {currentUserAffiliate?.followers &&
                            currentUserAffiliate?.followers.length > 0 &&
                            currentUserAffiliate?.followers.map((follower) => (
                              <AffiliateCard>
                                <div className="card_top">
                                  <div className="left">
                                    {!affiliateList[follower].userRef?.photo ? (
                                      <div className="user_circle">
                                        <p>
                                          {affiliateList[
                                            follower
                                          ].userRef?.firstname.slice(0, 1)}
                                        </p>
                                      </div>
                                    ) : (
                                      <img
                                        src={
                                          affiliateList[follower].userRef?.photo
                                        }
                                        alt=""
                                        style={{
                                          width: "44px",
                                          height: "44px",
                                          borderRadius: "100%",
                                        }}
                                      />
                                    )}

                                    <div className="info">
                                      <p className="name">
                                        {
                                          affiliateList[follower].userRef
                                            ?.firstname
                                        }
                                        {
                                          affiliateList[follower].userRef
                                            ?.lastname
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="card_mid">
                                  <span className="followers">
                                    <p>Followers</p>
                                    <p>
                                      {affiliateList[follower].settings
                                        ?.fakeFollowers
                                        ? formatnumber(
                                            affiliateList[follower].settings
                                              ?.followersFaux
                                          )
                                        : formatnumber(
                                            affiliateList[follower]?.followers
                                              .length
                                          )}{" "}
                                    </p>
                                  </span>

                                  <span className="following">
                                    <p>Following</p>
                                    <p>
                                      {affiliateList[follower].settings
                                        ?.fakeFollowing
                                        ? formatnumber(
                                            affiliateList[follower].settings
                                              ?.followingFaux
                                          )
                                        : formatnumber(
                                            affiliateList[follower].following
                                              ?.length
                                          )}
                                    </p>
                                  </span>

                                  <span className="referral">
                                    <p>Referral code</p>
                                    <p>
                                      {affiliateList[follower]?.referralCode}
                                    </p>
                                  </span>
                                </div>

                                <div className="card_bottom">
                                  <FullButton
                                    className="variant"
                                    onClick={() =>
                                      handleViewAffiliate(
                                        affiliateList[follower]
                                      )
                                    }
                                  >
                                    <p>View account</p>
                                  </FullButton>
                                </div>
                              </AffiliateCard>
                            ))}

                          {currentUserAffiliate?.followers &&
                            !currentUserAffiliate?.followers.length > 0 && (
                              <p style={{ color: "white", fontWeight: "500" }}>
                                No followers yet
                              </p>
                            )}
                        </CardsWithGrid>
                      )}

                      {/* folowing */}
                      {!searched && selectedTab === "Following" && (
                        <CardsWithGrid>
                          {currentUserAffiliate?.following &&
                            currentUserAffiliate?.following.length > 0 &&
                            currentUserAffiliate?.following.map((following) => (
                              <AffiliateCard>
                                <div className="card_top">
                                  <div className="left">
                                    {!affiliateList[following].userRef
                                      ?.photo ? (
                                      <div className="user_circle">
                                        <p>
                                          {affiliateList[
                                            following
                                          ].userRef?.firstname.slice(0, 1)}
                                        </p>
                                      </div>
                                    ) : (
                                      <img
                                        src={
                                          affiliateList[following].userRef
                                            ?.photo
                                        }
                                        alt=""
                                        style={{
                                          width: "44px",
                                          height: "44px",
                                          borderRadius: "100%",
                                        }}
                                      />
                                    )}

                                    <div className="info">
                                      <p className="name">
                                        {
                                          affiliateList[following].userRef
                                            ?.firstname
                                        }

                                        {
                                          affiliateList[following].userRef
                                            ?.lastname
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="card_mid">
                                  <span className="followers">
                                    <p>Followers</p>
                                    <p>
                                      {" "}
                                      {affiliateList[following].settings
                                        ?.fakeFollowers
                                        ? formatnumber(
                                            affiliateList[following].settings
                                              ?.followersFaux
                                          )
                                        : formatnumber(
                                            affiliateList[following]?.followers
                                              .length
                                          )}
                                    </p>
                                  </span>

                                  <span className="following">
                                    <p>Following</p>
                                    <p>
                                      {affiliateList[following].settings
                                        ?.fakeFollowing
                                        ? formatnumber(
                                            affiliateList[following].settings
                                              ?.followingFaux
                                          )
                                        : formatnumber(
                                            affiliateList[following].following
                                              ?.length
                                          )}
                                    </p>
                                  </span>

                                  <span className="referral">
                                    <p>Referral code</p>
                                    <p>
                                      {affiliateList[following]?.referralCode}
                                    </p>
                                  </span>
                                </div>

                                <div className="card_bottom">
                                  <FullButton
                                    className="variant"
                                    onClick={() =>
                                      handleViewAffiliate(
                                        affiliateList[following]
                                      )
                                    }
                                  >
                                    <p>View account</p>
                                  </FullButton>

                                  <>
                                    {affiliateList[
                                      following
                                    ]?.followers.includes(userData.id) && (
                                      <FullButton
                                        onClick={() =>
                                          handleUnfollow(
                                            affiliateList[following]
                                          )
                                        }
                                        disabled={
                                          isUnfollowingUser &&
                                          selectedUnfollowUser ===
                                            affiliateList[following]?.id
                                        }
                                        className={
                                          isUnfollowingUser &&
                                          selectedUnfollowUser ===
                                            affiliateList[following]?.id
                                            ? "delete disabled"
                                            : "delete"
                                        }
                                      >
                                        {isUnfollowingUser &&
                                        selectedUnfollowUser ===
                                          affiliateList[following]?.id ? (
                                          <div style={{ padding: "8px" }}>
                                            <CircularLoader
                                              bg="#cccccc"
                                              size="28"
                                              color="#ffffff"
                                            />
                                          </div>
                                        ) : (
                                          <p>Unfollow</p>
                                        )}
                                      </FullButton>
                                    )}

                                    {!affiliateList[
                                      following
                                    ]?.followers.includes(userData.id) && (
                                      <FullButton
                                        onClick={() =>
                                          handleFollow(affiliateList[following])
                                        }
                                        disabled={
                                          isFollowingUser &&
                                          selectedFollowUser ===
                                            affiliateList[following]?.id
                                        }
                                        className={
                                          isFollowingUser &&
                                          selectedFollowUser ===
                                            affiliateList[following]?.id &&
                                          "disabled"
                                        }
                                      >
                                        {isFollowingUser &&
                                        selectedFollowUser ===
                                          affiliateList[following]?.id ? (
                                          <div style={{ padding: "8px" }}>
                                            <CircularLoader
                                              bg="#cccccc"
                                              size="28"
                                              color="#ffffff"
                                            />
                                          </div>
                                        ) : (
                                          <p>Follow</p>
                                        )}
                                      </FullButton>
                                    )}
                                  </>
                                </div>
                              </AffiliateCard>
                            ))}

                          {currentUserAffiliate?.following &&
                            !currentUserAffiliate?.following.length > 0 && (
                              <p style={{ color: "white", fontWeight: "500" }}>
                                Not following anyone yet
                              </p>
                            )}
                        </CardsWithGrid>
                      )}
                    </AffiliateContainerStandard>
                  )}

                {selectedUserAffiliate && (
                  <SingleAffiliate
                    user={{
                      selectedUserAffiliate,
                      setSelectedUserAffiliate,
                    }}
                    prices={currentPrices}
                    accounts={accounts}
                  />
                )}
              </div>
            </div>
          </MainPage>
        </>
      )}
    </MainContainer>
  );
};

const AffiliateCard = styled.div`
  padding: 24px;
  background-color: #151823;
  height: 100%;
  border-radius: 12px;

  .card_top {
    display: flex;
    justify-content: space-between;
  }

  .card_top .left {
    display: flex;
    gap: 12px;
  }

  .card_top .info {
    align-self: center;
    cursor: pointer;
  }

  .card_top .info .name {
    color: white;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }

  .card_top img {
    width: 48px;
    height: 48px;
    border-radius: 100%;
  }

  .card_mid {
    /* display: flex;
    justify-content: space-between;
    padding: 32px 0px; */
    display: grid;
    grid-template-areas: "followers following" "referral referral";
    padding: 24px 0px;
    gap: 4px;
  }

  .card_mid .followers {
    text-transform: uppercase;
    display: grid;
    justify-items: center;
    padding: 24px;
    background-color: #1b1f2d;
    border-radius: 4px;
    grid-area: followers;
    gap: 4px;
  }

  .card_mid .following {
    text-transform: uppercase;
    display: grid;
    justify-items: center;
    padding: 24px;
    background-color: #1b1f2d;
    border-radius: 4px;
    grid-area: following;
    gap: 4px;
  }

  .card_mid .referral {
    text-transform: uppercase;
    display: grid;
    justify-items: center;
    padding: 24px;
    background-color: #1b1f2d;
    border-radius: 4px;
    width: 100%;
    gap: 4px;
    grid-area: referral;
  }

  /* .card_mid span {
    display: grid;
    gap: 4px;
    text-transform: uppercase;
    display: grid;
    justify-items: center;
    padding: 24px;
    background-color: #1b1f2d;
    border-radius: 4px;
    gap: 4px;
  } */

  .card_mid span p:nth-child(1) {
    font-size: 14px;
    font-weight: 600;
    color: #bac2de;
    text-transform: uppercase;
  }

  .card_mid span p:nth-child(2) {
    color: white;
    font-size: 16px;
    font-weight: 500;
  }

  .card_bottom {
    display: flex;
    gap: 6px;
  }
`;

const AffiliateContainerStandard = styled.div`
  .user_info_top {
    display: flex;
    gap: 24px;
    align-items: center;
  }

  @media screen and (max-width: 868px) {
    .user_info_top {
      flex-direction: column;
      align-items: start;
    }
  }

  .user_circle {
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: #1b1f2d;
    display: grid;
    color: #bac2de;
    font-weight: 600;
    font-size: 18px;
    align-content: center;
  }

  .user_circle p {
    width: 40px;
    text-align: center;
  }

  .tags {
    margin-top: 4px;
    display: flex;
    gap: 4px;
  }

  .expert_status {
    font-size: 12px;
    line-height: 18px;
    padding: 2px 8px;
    display: flex;
    gap: 4px;
    border-radius: 16px;
    border: 1px solid #b2ddff;
    background-color: #eff8ff;
    max-width: max-content;
    color: #175cd3;
    font-weight: 600;
    font-family: Inter;
    align-items: center;
  }

  .user_info_top img {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    object-fit: cover;
  }

  .pending {
    margin-top: 24px;
    color: #f7931a;
    display: flex;
    gap: 6px;
    font-size: 16px;
    align-items: center;
  }

  .pending svg {
    width: 24px;
  }

  .user_info_top span {
    display: grid;
    gap: 8px;
  }

  .user_info_top span .wrapper {
    display: flex;
    gap: 6px;
    align-items: center;
    color: #bac2de;
    cursor: pointer;
  }

  .user_info_top span .wrapper svg {
    width: 24px;
  }

  .user_info_top span .wrapper:hover {
    color: #939bb8;
  }

  .user_info_top span .wrapper:hover svg {
    fill: #939bb8;
  }

  .user_info_top span .user_name {
    font-size: 24px;
    font-weight: 600;
    color: white;
  }

  .user_info_top span .user_email {
    font-size: 14px;
    font-weight: 400;
    color: #bac2de;
  }

  .user_info_top span .user_code {
    font-size: 14px;
    font-weight: 400;
    color: #bac2de;
  }

  .user_info_top span .followers_info {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    color: #bac2de;
    align-items: center;
    gap: 6px;
    margin-top: 8px;
  }
  /* overflow-x: scroll; */

  .user_info_bottom {
    display: flex;
    gap: 6px;
    /* flex-direction: column; */
    /* align-items: center; */
    /* white-space: nowrap; */
  }
`;

export default Affiliate;

// user is affiliated, or not

// user is affiliated => show profile
// user is not affiliated => show modal
