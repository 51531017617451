import { useContext, useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { styled } from "styled-components";
import { ClickAwayListener } from "@mui/material";
import { LargeDivider } from "../styled/forms/dividers";
import {
  AmountBox,
  DropDownBox,
  FullButton,
  MiniAmountBox,
  ToolTipContainer,
} from "../styled/input/Input";

import {
  doc,
  increment,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "@firebase/firestore";
import { db } from "../firebase/firebase";
import CircularLoader from "../styled/loaders/CircularLoader";
import Toast from "../hooks/Toast";
import { context } from "../context/context";
import { formatterZero } from "../utils/utils";

const SubscriptionModalAffiliate = ({ open, pool, accounts, prices, user }) => {
  const { sub, setSub } = open;

  const {
    ref,
    asset,
    amount,
    duration,
    roi,
    currentInvested,
    currentRoi,
    affiliated,
    name,
  } = pool;

  const [cryptoAccount, setCryptoAccount] = useState({});
  const [balance, setBalance] = useState(undefined);

  // toast
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(" ");
  const [toastType, setToastType] = useState(undefined);

  const { id } = user;

  const [investedPercent, setInvestedPercent] = useState(null);

  const [investedRoi, setInvestedRoi] = useState(currentRoi);

  const { userData } = useContext(context);

  useEffect(() => {
    if (prices && accounts) {
      const live = accounts.live;
      if (live) {
        setCryptoAccount(live.Crypto);
        setBalance(live.Fiat.value);
      }
    }
  }, [prices, accounts]);

  // amount
  const [investedAmount, setInvestedAmount] = useState("");
  const amountRef = useRef();
  const [amountError, setAmountError] = useState(false);

  function handleAmount(e) {
    const { value } = e.target;

    if (Number(value) && Number(value) > 0.00005) {
      const tol = Number(value) + Number(currentInvested);
      setInvestedAmount(tol);
      const percentOfAmount = (Number(tol) * 100) / Number(amount);
      const roiOfUser = (percentOfAmount / 100) * roi;
      setInvestedRoi(roiOfUser);

      if (Number(value) > balance) {
        // value check
        setToolTipMessage(
          `Your current balance is ${formatterZero.format(
            Number(balance)
          )} but you are attempting to subscribe ${formatterZero.format(
            Number(e.target.value)
          )}, which is over your available balance`
        );
        setAmountError(true);
      } else if (Number(value) > Number(amount)) {
        // minimum check
        setAmountError(true);
        setToolTipMessage(
          `You cannot invest over 100% of the current subscription amount`
        );
      } else if (Number(value) && Number(value) === 0.00005) {
        setInvestedAmount(currentInvested);
        setInvestedRoi(currentRoi);
      } else {
        // valid
        setAmountError(false);
        const tol = Number(value) + Number(currentInvested);
        setInvestedAmount(tol);
        const percentOfAmount = (Number(tol) * 100) / Number(amount);
        const roiOfUser = (percentOfAmount / 100) * roi;
        setInvestedRoi(roiOfUser);
      }
    } else {
      // empty value
      setInvestedAmount("");
      setInvestedRoi(currentRoi);
    }
  }
  const [showToolTip, setShowToolTip] = useState(false);
  const [tooltipMessage, setToolTipMessage] = useState("");

  // roi & compiled
  const [compiledTotal, setCompiledTotal] = useState(0);
  const [showCompiledToolTip, setShowCompiledToolTip] = useState(false);
  const compiledToolTipMessage = useState(
    `This is the amount you will earn after the subscription completes in ${duration} ${
      Number(duration) === 1 ? "day" : "days"
    } plus your initial investment`
  );

  const [isInvesting, setIsInvesting] = useState(false);
  function handleInvest() {
    setIsInvesting(true);
    refundOwner();
  }

  async function refundOwner() {
    const q = doc(db, "balances", id);

    try {
      await updateDoc(q, {
        affiliateBalance: increment(
          Number(
            prices[asset] * (Number(investedAmount) - Number(currentInvested))
          )
        ),
      }).then(() => {
        decrementAmount();
      });
    } catch (error) {
      // setIsSubscribing(false);
      setIsInvesting(false);
      setToastType("error");
      setToastMessage("Failed to invest. Please try again later.");
      setOpenToast(true);
    }
  }

  async function decrementAmount() {
    const q = doc(db, "accounts", userData.id);
    const key = `live.Fiat.value`;

    try {
      await updateDoc(q, {
        [key]: increment(-(Number(investedAmount) - Number(currentInvested))),
      }).then(() => {
        subscribe();
      });
    } catch (error) {
      setIsInvesting(false);
      setToastType("error");
      setToastMessage("Failed to invest. Please try again later.");
      setOpenToast(true);
    }
  }

  // async function sendAdminNotification() {}
  async function subscribe() {
    const q = doc(db, "subscriptions", ref);
    const key = `affiliates.${userData.id}`;

    let fauxObj = {};

    if (!affiliated) {
      fauxObj = {
        amount: increment(Number(investedAmount) - Number(currentInvested)),
        affiliated: true,
        original: Number(amount),
        [key]: {
          user: userData.id,
          investedAmount,
          investedRoi,
        },
      };
    } else {
      fauxObj = {
        amount: increment(Number(investedAmount) - Number(currentInvested)),
        affiliated: true,
        [key]: {
          user: userData.id,
          investedAmount,
          investedRoi,
        },
      };
    }

    await updateDoc(q, fauxObj)
      .then(() => {
        sendUserNotification();
      })
      .catch((error) => {
        // console.log(error)
        setIsInvesting(false);
        setToastType("error");
        setToastMessage("Failed to invest. Please try again later.");
        setOpenToast(true);
      });
  }

  function reset() {
    if (amountRef) {
      amountRef.current.value = " ";
    }

    setAmountError(false);
    setInvestedAmount(undefined);
  }

  // user notified
  async function sendUserNotification() {
    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      userData.id.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    await setDoc(doc(db, "notifications", str), {
      ref: str,
      type: "Subscription",
      message: "You subscribed to " + name + " plan",
      user: userData.id,
      read: false,
      date: serverTimestamp(),
    })
      .then(() => {
        setIsInvesting(false);
        setToastType("success");
        setToastMessage("Invested successfully");
        setOpenToast(true);
        // reset();
        setTimeout(() => {
          setSub(false);
        }, 400);
      })
      .catch((error) => {
        setIsInvesting(false);
        setToastType("error");
        setToastMessage("Failed to invest. Please try again later.");
        setOpenToast(true);
      });
  }

  useEffect(() => {
    if (investedRoi && investedAmount) {
      const totalAmount = amount + investedAmount;
      setCompiledTotal(
        Number(
          investedAmount + Number((investedRoi / 100) * totalAmount)
        ).toFixed(2)
      );
    } else {
      //   const totalAmount = amount + investedAmount;
      setCompiledTotal(
        Number(
          Number(currentInvested) + (Number(investedRoi) / 100) * amount
        ).toFixed(2)
      );
    }
  }, [roi, investedAmount]);

  const [showRoiTooltip, setShowRoiTooltip] = useState(false);
  const roiTooltipMessage = "This is the total % of your subscription earnings";

  return (
    <>
      {openToast && (
        <Toast
          open={{ openToast, setOpenToast }}
          message={toastMessage}
          type={toastType}
        />
      )}

      <Modal
        open={sub}
        onClose={() => setSub(false)}
        style={{
          display: "flex",
          placeContent: "center",
          zIndex: "10001",
        }}
      >
        <ModalStandard className="scrollbar-hide">
          <div className="modal_top">
            <p>Subscribe</p>

            <svg
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setSub(!sub)}
              style={{ cursor: "pointer" }}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.8647 0.366365C12.3532 -0.122122 13.1451 -0.122122 13.6336 0.366365C14.1221 0.854853 14.1221 1.64685 13.6336 2.13533L8.88929 6.87968L13.8743 11.8647C14.3628 12.3532 14.3628 13.1451 13.8743 13.6336C13.3858 14.1221 12.5938 14.1221 12.1053 13.6336L7.12032 8.64864L2.13533 13.6336C1.64685 14.1221 0.854853 14.1221 0.366366 13.6336C-0.122122 13.1451 -0.122122 12.3532 0.366366 11.8647L5.35136 6.87968L0.607014 2.13533C0.118527 1.64685 0.118527 0.854853 0.607014 0.366365C1.0955 -0.122122 1.8875 -0.122122 2.37598 0.366365L7.12032 5.11071L11.8647 0.366365Z"
                fill="#858DAD"
              />
            </svg>
          </div>

          <div className="modal_content">
            <div className="top">
              <LargeDivider className="variant">
                {/* currently staked */}
                <AmountBox className={"amount_box"}>
                  <div className="label">
                    <p>Subscription amount:</p>
                  </div>

                  <div className="wrapper">
                    <input type="number" value={amount} disabled />

                    <span className="asset">
                      <span>
                        <img src={`./asseticons/${asset}.svg`} alt="" />
                        <p>{asset}</p>
                      </span>
                    </span>
                  </div>
                </AmountBox>

                {/* your stake */}
                <AmountBox
                  className={amountError ? "amount_box error" : "amount_box"}
                >
                  <div className="label">
                    <p>Your amount:</p>
                    <img
                      src="./assets/misc/info.svg"
                      alt=""
                      className="error_inform"
                      id="popcorn"
                      onClick={() => setShowToolTip(!showToolTip)}
                    />
                    {showToolTip && (
                      <ClickAwayListener
                        onClickAway={() => setShowToolTip(false)}
                      >
                        <div className="tooltip" id="tooltip">
                          {tooltipMessage}
                        </div>
                      </ClickAwayListener>
                    )}
                  </div>

                  <div className="wrapper">
                    <input
                      type="number"
                      placeholder={currentInvested}
                      onChange={handleAmount}
                      ref={amountRef}
                    />

                    <span className="asset">
                      <span>
                        <img src={`./asseticons/${asset}.svg`} alt="" />
                        <p>{asset}</p>
                      </span>
                    </span>
                  </div>

                  {asset && (
                    <div className="captions">
                      <span>
                        <p className="caption">Current balance</p>
                        <p className="value">
                          {Math.floor(Number(balance))} {asset}{" "}
                        </p>
                      </span>
                    </div>
                  )}
                </AmountBox>

                {/* roi */}
                <MiniAmountBox className={"amount_box"}>
                  <div className="label">
                    <p>Your ROI:</p>
                    <img
                      src="./assets/misc/info.svg"
                      alt=""
                      className="error_inform"
                      id="popcorn"
                      style={{ display: "block" }}
                      onClick={() => setShowRoiTooltip(!showRoiTooltip)}
                    />
                    {showRoiTooltip && (
                      <ClickAwayListener
                        onClickAway={() => setShowRoiTooltip(false)}
                      >
                        <div
                          className="tooltip"
                          id="tooltip"
                          style={{ left: "1px" }}
                        >
                          {roiTooltipMessage}
                        </div>
                      </ClickAwayListener>
                    )}
                  </div>

                  <div className="wrapper" style={{ padding: "24px" }}>
                    <input
                      type="number"
                      placeholder={Math.round(Number(investedRoi)) + "%"}
                      //   onChange={(e) => handleDuration(e)}
                      disabled
                    />
                  </div>
                </MiniAmountBox>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      maxWidth: "180px",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    {showCompiledToolTip && (
                      <ClickAwayListener
                        onClickAway={() =>
                          setShowCompiledToolTip(!showCompiledToolTip)
                        }
                      >
                        <ToolTipContainer>
                          <div
                            className="tooltip"
                            id="tooltip"
                            style={{
                              bottom: "28px",
                              left: "5px",
                              width: "100%",
                            }}
                          >
                            {compiledToolTipMessage}
                          </div>
                        </ToolTipContainer>
                      </ClickAwayListener>
                    )}
                    <p
                      style={{
                        color: "#bac2de",
                        fontSize: "14px",
                        lineHeight: "20px",
                      }}
                    >
                      Total returns after subscription ends
                      <span style={{ position: "absolute", marginLeft: "4px" }}>
                        <img
                          src="./assets/misc/info.svg"
                          alt=""
                          className="error_inform"
                          id="popcorn"
                          onClick={() =>
                            setShowCompiledToolTip(!showCompiledToolTip)
                          }
                        />
                      </span>
                    </p>
                  </span>
                  <span>
                    <p
                      style={{
                        fontSize: "20px",
                        color: "#5BDE4C",
                        fontWeight: "600",
                      }}
                    >
                      ~{compiledTotal} {asset}
                    </p>
                  </span>
                </div>
              </LargeDivider>
            </div>

            <div className="bottom">
              <FullButton
                onClick={handleInvest}
                disabled={
                  isInvesting ||
                  !asset ||
                  !investedAmount ||
                  amountError ||
                  !investedRoi
                }
                className={
                  (isInvesting ||
                    !asset ||
                    !investedAmount ||
                    amountError ||
                    !investedRoi) &&
                  "disabled"
                }
              >
                {isInvesting ? (
                  <div style={{ padding: "8px" }}>
                    <CircularLoader bg="#cccccc" size="28" color="#ffffff" />
                  </div>
                ) : (
                  <p>Subscribe</p>
                )}
              </FullButton>
            </div>
          </div>
        </ModalStandard>
      </Modal>
    </>
  );
};

const ModalStandard = styled.div`
  background-color: #151823;
  border-radius: 12px;
  max-width: 430px;
  place-self: center;
  width: 100%;
  border: 1px solid transparent;
  z-index: 10001;

  .bottom button {
    cursor: pointer;
    width: 100%;
    background-color: #0c6cf2;
    padding: 12px;
    color: white;
    font-size: 14px;
    font-weight: 600;
    border: none;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
  }

  .bottom button:hover {
    background-color: #ff3344;
  }

  .bottom {
    margin-top: 32px;
  }

  @media screen and (max-width: 768px) {
    width: 100vw;
    max-width: unset;
    height: fit-content;
    max-height: 90vh;
    position: fixed;
    left: 0;
    bottom: 0;
    padding-bottom: 48px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    /* overflow-y: scroll; */

    .bottom button {
      margin: 0;
    }

    .top {
      margin-bottom: 52px;
    }

    .bottom {
      position: fixed;
      bottom: 0px;
      right: 0px;
      width: 100%;
      padding: 12px 24px;
      height: fit-content;
      background-color: #151823;
      z-index: 999;
      border: none;
      outline: none;
      /* display: none; */
    }
  }

  .modal_top {
    color: white;
    font-size: 16px;
    font-weight: 600;
    background-color: #1b1f2d;
    width: 100%;
    padding: 14px 30px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    position: sticky;
    top: 0;
    z-index: 999;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal_content {
    padding: 24px;
  }

  .modal_content .top {
    display: grid;
    gap: 24px;
  }
`;

export default SubscriptionModalAffiliate;
