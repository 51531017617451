import { useContext, useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { styled } from "styled-components";
import { FilledButton, FullButton, TextBox } from "../styled/input/Input";
import CircularLoader from "../styled/loaders/CircularLoader";
import { db, storage } from "../firebase/firebase";
import { deleteField, doc, setDoc, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { context } from "../context/context";
import Toast from "../hooks/Toast";

const AffiliateSettingsModalAdmin = ({ open, user }) => {
  const { editSettings, setEditSettings } = open;

  // toast
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(" ");
  const [toastType, setToastType] = useState(undefined);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const [isSaving, setIsSaving] = useState(false);

  async function handleSave() {
    setIsSaving(true);
    handleSaveSettings();
  }

  useEffect(() => {
    console.log(user);
  }, []);

  async function handleSaveSettings() {
    const q = doc(db, "affiliates", user.id);

    // const key = `settings`;

    try {
      await updateDoc(q, {
        settings: {
          fakeFollowers,
          followersFaux: followers,
          fakeFollowing,
          followingFaux: following,
          useTag,
          tag,
        },
      });
      setIsSaving(false);
      setToastType("success");
      setToastMessage("Successfully saved settings");
      setOpenToast(true);
      setTimeout(() => {
        setEditSettings(false);
      }, 600);
    } catch (error) {
      setIsSaving(false);
      console.log(error);
      setToastType("error");
      setToastMessage("Could not save. Please try again later");
      setOpenToast(true);
      // toast.error("Could not update. Please try again later");
    }
  }

  const [fakeFollowers, setFakeFollowers] = useState(
    user.settings?.fakeFollowers
  );
  const [followers, setFollowers] = useState(
    user.settings?.followersFaux || null
  );

  function handleFollowers(e) {
    const { value } = e.target;

    if (value) {
      setFollowers(Number(value));
    } else {
      setFollowers(null);
    }
  }

  const [fakeFollowing, setFakeFollowing] = useState(
    user.settings?.fakeFollowing
  );
  const [following, setFollowing] = useState(
    user.settings?.followingFaux || null
  );
  function handleFollowing(e) {
    const { value } = e.target;

    if (value) {
      setFollowing(Number(value));
    } else {
      setFollowing(null);
    }
  }

  const [useTag, setUseTag] = useState(user.settings?.tag || false);
  const [tag, setTag] = useState(user.settings?.tag || null);
  function handleTag(e) {
    const { value } = e.target;

    if (value) {
      setTag(value);
    } else {
      setTag(null);
    }
  }

  //   const [hideFollowers, setHideFollowers] = useState(false);

  //   const [hideFollowing, setHideFollowing] = useState(false);

  return (
    <Modal
      open={editSettings}
      onClose={() => setEditSettings(false)}
      style={{
        display: "flex",
        placeContent: "center",
        zIndex: "10001",
        padding: "12px",
      }}
    >
      <>
        {openToast && (
          <Toast
            open={{ openToast, setOpenToast }}
            message={toastMessage}
            type={toastType}
          />
        )}

        <ModalStandard className="scrollbar-hide">
          <div className="modal_top">
            <p>Edit Settings</p>

            <svg
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setEditSettings(!editSettings)}
              style={{ cursor: "pointer" }}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.8647 0.366365C12.3532 -0.122122 13.1451 -0.122122 13.6336 0.366365C14.1221 0.854853 14.1221 1.64685 13.6336 2.13533L8.88929 6.87968L13.8743 11.8647C14.3628 12.3532 14.3628 13.1451 13.8743 13.6336C13.3858 14.1221 12.5938 14.1221 12.1053 13.6336L7.12032 8.64864L2.13533 13.6336C1.64685 14.1221 0.854853 14.1221 0.366366 13.6336C-0.122122 13.1451 -0.122122 12.3532 0.366366 11.8647L5.35136 6.87968L0.607014 2.13533C0.118527 1.64685 0.118527 0.854853 0.607014 0.366365C1.0955 -0.122122 1.8875 -0.122122 2.37598 0.366365L7.12032 5.11071L11.8647 0.366365Z"
                fill="#858DAD"
              />
            </svg>
          </div>

          {isLoading ? (
            <div
              style={{
                backgroundColor: "#151823",
                borderBottomLeftRadius: "12px",
                borderBottomRightRadius: "12px",
                padding: "60px",
              }}
            >
              <CircularLoader
                bg="rgba(12, 108, 243, 0.2)"
                size="28"
                color="#0C6CF2"
              />
            </div>
          ) : (
            <div className="modal_content">
              <div className="top">
                {/* followers */}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    border: "1px solid #222739",
                    backgroundColor: "rgba(27, 31, 45, 0.3)",
                    borderRadius: "12px",
                    padding: "16px 24px",
                    alignItems: "center",
                  }}
                >
                  <span>
                    <p
                      style={{
                        color: "#fff",
                        fontWeight: "500",
                        lineHeight: "28px",
                      }}
                    >
                      Fake followers
                    </p>
                    <p
                      style={{
                        color: "#bac2de",
                        fontSize: "14px",
                        lineHeight: "24px",
                      }}
                    >
                      Set a specific number for followers
                    </p>
                  </span>

                  <ToggleSwitch
                    className={fakeFollowers && "toggled"}
                    onClick={() => setFakeFollowers(!fakeFollowers)}
                  >
                    <div className="toggle"></div>
                  </ToggleSwitch>
                </div>

                {fakeFollowers && (
                  <TextBox className="scrollbar-hide">
                    <label htmlFor="address">Followers:</label>
                    <br />

                    <input
                      type="text"
                      placeholder={followers || "Number of followers"}
                      onChange={handleFollowers}
                    />
                  </TextBox>
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    border: "1px solid #222739",
                    backgroundColor: "rgba(27, 31, 45, 0.3)",
                    borderRadius: "12px",
                    padding: "16px 24px",
                    alignItems: "center",
                  }}
                >
                  <span>
                    <p
                      style={{
                        color: "#fff",
                        fontWeight: "500",
                        lineHeight: "28px",
                      }}
                    >
                      Fake following
                    </p>
                    <p
                      style={{
                        color: "#bac2de",
                        fontSize: "14px",
                        lineHeight: "24px",
                      }}
                    >
                      Set a specific number for following
                    </p>
                  </span>

                  <ToggleSwitch
                    className={fakeFollowing && "toggled"}
                    onClick={() => setFakeFollowing(!fakeFollowing)}
                  >
                    <div className="toggle"></div>
                  </ToggleSwitch>
                </div>

                {fakeFollowing && (
                  <TextBox className="scrollbar-hide">
                    <label htmlFor="address">Following:</label>
                    <br />
                    <input
                      type="text"
                      placeholder={following || "Number of following"}
                      onChange={handleFollowing}
                    />
                  </TextBox>
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    border: "1px solid #222739",
                    backgroundColor: "rgba(27, 31, 45, 0.3)",
                    borderRadius: "12px",
                    padding: "16px 24px",
                    alignItems: "center",
                  }}
                >
                  <span>
                    <p
                      style={{
                        color: "#fff",
                        fontWeight: "500",
                        lineHeight: "28px",
                      }}
                    >
                      Use Tag
                    </p>
                    <p
                      style={{
                        color: "#bac2de",
                        fontSize: "14px",
                        lineHeight: "24px",
                      }}
                    >
                      Set a tag to show in affiliate
                    </p>
                  </span>

                  <ToggleSwitch
                    className={useTag && "toggled"}
                    onClick={() => setUseTag(!useTag)}
                  >
                    <div className="toggle"></div>
                  </ToggleSwitch>
                </div>

                {useTag && (
                  <TextBox className="scrollbar-hide">
                    <label htmlFor="address">Tag:</label>
                    <br />
                    <input
                      type="text"
                      placeholder={tag || "Tag"}
                      onChange={handleTag}
                    />
                  </TextBox>
                )}

                <br />
              </div>

              <div
                className="buttons"
                style={{
                  display: "flex",
                  gap: "12px",
                  alignItems: "center",
                }}
              >
                <FullButton
                  disabled={isSaving}
                  onClick={handleSave}
                  className={isSaving && "disabled"}
                >
                  {isSaving ? (
                    <div style={{ padding: "8px" }}>
                      <CircularLoader bg="#cccccc" size="28" color="#ffffff" />
                    </div>
                  ) : (
                    <p>Save</p>
                  )}
                </FullButton>
              </div>
            </div>
          )}
        </ModalStandard>
      </>
    </Modal>
  );
};

const ToggleSwitch = styled.button`
  height: 24px;
  width: 48px;
  max-width: 48px;
  padding: 0;
  background-color: #222739;

  border-radius: 48px;
  display: flex;
  cursor: pointer;
  border: none;
  outline: none;
  /* margin: 0; */

  &.toggled {
    background-color: white;
  }

  .toggle {
    width: 22px;
    height: 22px;
    margin-left: 2px;
    border-radius: 100%;
    place-self: center;
    /* padding: 2px; */
    transition: all 300ms ease-in-out;
    background-color: #07080d;
  }

  &.toggled .toggle {
    transform: translateX(20px);
    margin-left: 4px;
  }
`;

const ModalStandard = styled.div`
  background-color: #151823;
  border-radius: 12px;
  max-width: 430px;
  place-self: center;
  width: 100%;
  border: 1px solid transparent;
  z-index: 10001;

  .bottom button {
    cursor: pointer;
    width: 100%;
    background-color: #0c6cf2;
    padding: 12px;
    color: white;
    font-size: 14px;
    font-weight: 600;
    border: none;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
  }

  .bottom button:hover {
    background-color: #ff3344;
  }

  .bottom {
    margin-top: 32px;
  }

  @media screen and (max-width: 768px) {
    width: 100vw;
    max-width: unset;
    height: fit-content;
    max-height: 90vh;
    position: fixed;
    left: 0;
    bottom: 0;
    padding-bottom: 48px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    /* overflow-y: scroll; */

    .bottom button {
      margin: 0;
    }

    .top {
      margin-bottom: 52px;
    }

    .bottom {
      position: fixed;
      bottom: 0px;
      right: 0px;
      width: 100%;
      padding: 12px 24px;
      height: fit-content;
      background-color: #151823;
      z-index: 999;
      border: none;
      outline: none;
      /* display: none; */
    }
  }

  .modal_top {
    color: white;
    font-size: 16px;
    font-weight: 600;
    background-color: #1b1f2d;
    width: 100%;
    padding: 14px 30px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    position: sticky;
    top: 0;
    z-index: 999;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal_content {
    padding: 24px;
  }

  .modal_content .top {
    display: grid;
    gap: 24px;
  }
`;

export default AffiliateSettingsModalAdmin;
