import { useEffect, useState } from "react";
import { PrimarySwitcher } from "../styled/input/Input";
import styled from "styled-components";
import { formatnumber } from "../utils/utils";
import StakingsAffiliateTable from "../components/affiliatesTables/StakingsAffiliateTable";
import SubscriptionsAffiliateTable from "../components/affiliatesTables/SubscriptionsAffiliateTable";
import RealEstateAffiliateTable from "../components/affiliatesTables/RealEstateAffiliateTable";

const SingleAffiliate = ({ user, prices, accounts }) => {
  const { selectedUserAffiliate, setSelectedUserAffiliate } = user;

  const singleAffiliateTabs = ["Stakings", "Subscriptions", "Real Estate"];

  const [selectedTab, setSelectedTab] = useState("Stakings");

  return (
    <SingleAffiliateStandard>
      <span className="go_back" onClick={() => setSelectedUserAffiliate(null)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#ffffff"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
          />
        </svg>

        <p>Back to your page</p>
      </span>

      <br />
      <br />

      <AffiliateContainerStandard>
        <div className="user_info_affiliate">
          {/* info */}
          <div className="user_info_top">
            <div>
              {selectedUserAffiliate.userRef?.photo ? (
                <img
                  src={selectedUserAffiliate.userRef?.photo}
                  alt=""
                  srcSet={selectedUserAffiliate.userRef?.photo}
                />
              ) : (
                <svg
                  style={{ width: "120px" }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#fff"
                  class="w-6 h-6"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              )}
            </div>

            <span>
              <p className="user_name">
                {selectedUserAffiliate.userRef?.firstname}
                {selectedUserAffiliate.userRef?.lastname}

                {selectedUserAffiliate.settings?.useTag && (
                  <div className="tags">
                    <span className="expert_status">
                      <p>{selectedUserAffiliate.settings?.tag}</p>
                    </span>
                  </div>
                )}
              </p>
              <span className="wrapper">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#BAC2DE"
                  class="w-6 h-6"
                >
                  <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                  <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                </svg>
                <p className="user_email">
                  {selectedUserAffiliate.userRef?.email}
                </p>
              </span>

              <span className="wrapper">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#BAC2DE"
                  class="w-6 h-6"
                  sty
                >
                  <path
                    fill-rule="evenodd"
                    d="M3 4.875C3 3.839 3.84 3 4.875 3h4.5c1.036 0 1.875.84 1.875 1.875v4.5c0 1.036-.84 1.875-1.875 1.875h-4.5A1.875 1.875 0 0 1 3 9.375v-4.5ZM4.875 4.5a.375.375 0 0 0-.375.375v4.5c0 .207.168.375.375.375h4.5a.375.375 0 0 0 .375-.375v-4.5a.375.375 0 0 0-.375-.375h-4.5Zm7.875.375c0-1.036.84-1.875 1.875-1.875h4.5C20.16 3 21 3.84 21 4.875v4.5c0 1.036-.84 1.875-1.875 1.875h-4.5a1.875 1.875 0 0 1-1.875-1.875v-4.5Zm1.875-.375a.375.375 0 0 0-.375.375v4.5c0 .207.168.375.375.375h4.5a.375.375 0 0 0 .375-.375v-4.5a.375.375 0 0 0-.375-.375h-4.5ZM6 6.75A.75.75 0 0 1 6.75 6h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75A.75.75 0 0 1 6 7.5v-.75Zm9.75 0A.75.75 0 0 1 16.5 6h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75a.75.75 0 0 1-.75-.75v-.75ZM3 14.625c0-1.036.84-1.875 1.875-1.875h4.5c1.036 0 1.875.84 1.875 1.875v4.5c0 1.035-.84 1.875-1.875 1.875h-4.5A1.875 1.875 0 0 1 3 19.125v-4.5Zm1.875-.375a.375.375 0 0 0-.375.375v4.5c0 .207.168.375.375.375h4.5a.375.375 0 0 0 .375-.375v-4.5a.375.375 0 0 0-.375-.375h-4.5Zm7.875-.75a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75a.75.75 0 0 1-.75-.75v-.75Zm6 0a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75a.75.75 0 0 1-.75-.75v-.75ZM6 16.5a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75a.75.75 0 0 1-.75-.75v-.75Zm9.75 0a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75a.75.75 0 0 1-.75-.75v-.75Zm-3 3a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75a.75.75 0 0 1-.75-.75v-.75Zm6 0a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75a.75.75 0 0 1-.75-.75v-.75Z"
                    clip-rule="evenodd"
                  />
                </svg>

                <p className="user_code">
                  {selectedUserAffiliate?.referralCode}
                </p>
              </span>

              <div className="followers_info">
                <p>
                  {selectedUserAffiliate.settings?.fakeFollowers
                    ? formatnumber(
                        selectedUserAffiliate.settings?.followersFaux
                      )
                    : formatnumber(
                        selectedUserAffiliate.followers?.length
                      )}{" "}
                  Followers
                </p>
                <p>•</p>
                <p>
                  {selectedUserAffiliate.settings?.fakeFollowing
                    ? formatnumber(
                        selectedUserAffiliate.settings?.followingFaux
                      )
                    : formatnumber(
                        selectedUserAffiliate.following?.length
                      )}{" "}
                  Following
                </p>
              </div>
            </span>
          </div>

          {/* buttons */}
        </div>

        <br />

        <PrimarySwitcher className="scrollbar-hide">
          {singleAffiliateTabs.map((tab) => (
            <button
              key={tab}
              className={tab === selectedTab && "active"}
              onClick={() => setSelectedTab(tab)}
            >
              {tab}
            </button>
          ))}
        </PrimarySwitcher>

        {selectedTab === "Stakings" && (
          <StakingsAffiliateTable
            user={selectedUserAffiliate}
            prices={prices}
            accounts={accounts}
          />
        )}

        {selectedTab === "Subscriptions" && (
          <SubscriptionsAffiliateTable
            user={selectedUserAffiliate}
            prices={prices}
            accounts={accounts}
          />
        )}

        {selectedTab === "Real Estate" && (
          <RealEstateAffiliateTable
            user={selectedUserAffiliate}
            prices={prices}
            accounts={accounts}
          />
        )}
      </AffiliateContainerStandard>
    </SingleAffiliateStandard>
  );
};

const AffiliateCard = styled.div`
  padding: 24px;
  background-color: #151823;
  height: 100%;
  border-radius: 12px;

  .card_top {
    display: flex;
    justify-content: space-between;
  }

  .card_top .left {
    display: flex;
    gap: 12px;
  }

  .card_top .info {
    align-self: center;
  }

  .card_top .info .name {
    color: white;
    font-size: 16px;
    font-weight: 500;
  }

  .card_top img {
    width: 48px;
    height: 48px;
    border-radius: 100%;
  }

  .card_mid {
    /* display: flex;
    justify-content: space-between;
    padding: 32px 0px; */
    display: grid;
    grid-template-areas: "followers following" "referral referral";
    padding: 24px 0px;
    gap: 4px;
  }

  .card_mid .followers {
    text-transform: uppercase;
    display: grid;
    justify-items: center;
    padding: 24px;
    background-color: #1b1f2d;
    border-radius: 4px;
    grid-area: followers;
    gap: 4px;
  }

  .card_mid .following {
    text-transform: uppercase;
    display: grid;
    justify-items: center;
    padding: 24px;
    background-color: #1b1f2d;
    border-radius: 4px;
    grid-area: following;
    gap: 4px;
  }

  .card_mid .referral {
    text-transform: uppercase;
    display: grid;
    justify-items: center;
    padding: 24px;
    background-color: #1b1f2d;
    border-radius: 4px;
    width: 100%;
    gap: 4px;
    grid-area: referral;
  }

  /* .card_mid span {
    display: grid;
    gap: 4px;
    text-transform: uppercase;
    display: grid;
    justify-items: center;
    padding: 24px;
    background-color: #1b1f2d;
    border-radius: 4px;
    gap: 4px;
  } */

  .card_mid span p:nth-child(1) {
    font-size: 14px;
    font-weight: 600;
    color: #bac2de;
    text-transform: uppercase;
  }

  .card_mid span p:nth-child(2) {
    color: white;
    font-size: 16px;
    font-weight: 500;
  }

  .card_bottom {
    display: flex;
    gap: 6px;
  }
`;

const AffiliateContainerStandard = styled.div`
  .user_info_top {
    display: flex;
    gap: 24px;
    align-items: center;
  }

  @media screen and (max-width: 868px) {
    .user_info_top {
      flex-direction: column;
      align-items: start;
    }
  }

  .tags {
    margin-top: 4px;
    display: flex;
    gap: 4px;
  }

  .expert_status {
    font-size: 12px;
    line-height: 18px;
    padding: 2px 8px;
    display: flex;
    gap: 4px;
    border-radius: 16px;
    border: 1px solid #b2ddff;
    background-color: #eff8ff;
    max-width: max-content;
    color: #175cd3;
    font-weight: 600;
    font-family: Inter;
    align-items: center;
  }

  .user_info_top img {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    object-fit: cover;
  }

  .pending {
    margin-top: 24px;
    color: #f7931a;
    display: flex;
    gap: 6px;
    font-size: 16px;
    align-items: center;
  }

  .pending svg {
    width: 24px;
  }

  .user_info_top span {
    display: grid;
    gap: 8px;
  }

  .user_info_top span .wrapper {
    display: flex;
    gap: 6px;
    align-items: center;
    color: #bac2de;
    cursor: pointer;
  }

  .user_info_top span .wrapper svg {
    width: 24px;
  }

  .user_info_top span .wrapper:hover {
    color: #939bb8;
  }

  .user_info_top span .wrapper:hover svg {
    fill: #939bb8;
  }

  .user_info_top span .user_name {
    font-size: 24px;
    font-weight: 600;
    color: white;
  }

  .user_info_top span .user_email {
    font-size: 14px;
    font-weight: 400;
    color: #bac2de;
  }

  .user_info_top span .user_code {
    font-size: 14px;
    font-weight: 400;
    color: #bac2de;
  }

  .user_info_top span .followers_info {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    color: #bac2de;
    align-items: center;
    gap: 6px;
    margin-top: 8px;
  }
  /* overflow-x: scroll; */

  .user_info_bottom {
    display: flex;
    gap: 6px;
    /* flex-direction: column; */
    /* align-items: center; */
    /* white-space: nowrap; */
  }
`;

const SingleAffiliateStandard = styled.div`
  .go_back {
    display: flex;
    gap: 6px;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    text-decoration: underline;
    /* background-color: #0a57c218; */
    max-width: max-content;
    border: 4px;
    padding: 8px 12px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  .go_back p {
  }

  .go_back:hover {
    color: #0c6cf2;
  }

  .go_back svg {
    width: 18px;
    height: 18px;
  }
`;

export default SingleAffiliate;
